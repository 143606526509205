<template>
  <div class="modal">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header">
        <h3>
          W9 Information
        </h3>
      </div>
      <div class="modal-body">
        <div class="text-center mt-4" v-if="ui.loading">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div v-else>
          <div class="modal-body-container">
            <div class="d-flex align-items-center">
              <v-avatar :username="agent.name" :size="42" rounded />
              <div class="user-name">
                {{ agent.name }}
              </div>
              <div class="user-position">
                {{ agent.position }}
              </div>
            </div>
          </div>
          <div class="modal-body-container border-0">
            <div class="row">
              <div class="col-6">
                <div class="form-group full-width">
                  <label for="fieldCompanyPayee">
                    Company Payee
                  </label>
                  <input type="text" class="form-control" id="fieldCompanyPayee"
                         v-model="formData.companyPayee"
                         :class="{'input-filled': formData.companyPayee.length}">
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-6">
                <div class="form-group full-width">
                  <label for="fieldTax">
                    Tax Classification
                  </label>
                  <multiselect v-model="formData.tax"
                               id="fieldTax"
                               track-by="value" label="label"
                               class="full-width profile-visibility w-md-100"
                               :options="taxes"
                               :multiple="false"
                               :selectedLabel="'✓'"
                               :searchable="false"
                               :selectLabel="''"
                               :deselectLabel="''"
                               :close-on-select="true"
                               :allow-empty="false">
                  </multiselect>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group full-width">
                  <label for="fieldTaxId">
                    Tax Id
                  </label>
                  <input type="text" class="form-control" id="fieldTaxId"
                         v-model="formData.taxId"
                         :class="{'input-filled': formData.taxId.length}">
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-6">
                <div class="form-group full-width">
                  <label for="fieldSSN">
                    SSN
                  </label>
                  <input type="text" class="form-control" id="fieldSSN"
                         v-model="formData.SSN" :class="{'input-filled': formData.SSN.length}">
                </div>
              </div>
              <div class="col-12 mt-4">
                <div class="form-group full-width">
                  <label for="fieldAddress">
                    Address
                  </label>
                  <input type="text" class="form-control" id="fieldAddress"
                         v-model="formData.address"
                         :class="{'input-filled': formData.address.length}">
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-6">
                <div class="form-group full-width">
                  <label for="fieldCity">
                    City
                  </label>
                  <input type="text" class="form-control" id="fieldCity"
                         v-model="formData.city"
                         :class="{'input-filled': formData.city.length}">
                </div>
              </div>
              <div class="col-3">
                <div class="form-group full-width">
                  <label for="fieldState">
                    State
                  </label>
                  <multiselect v-model="formData.state"
                               id="fieldState"
                               track-by="value" label="label"
                               class="full-width profile-visibility w-md-100"
                               :options="states"
                               :multiple="false"
                               :selectedLabel="'✓'"
                               :searchable="false"
                               :selectLabel="''"
                               :deselectLabel="''"
                               :close-on-select="true"
                               :allow-empty="false">
                  </multiselect>
                </div>
              </div>
              <div class="col-3">
                <div class="form-group full-width">
                  <label for="fieldZip">
                    Zip
                  </label>
                  <input type="text" class="form-control" id="fieldZip"
                         v-model="formData.zip"
                         :class="{'input-filled': formData.zip.length}">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-start" v-if="ui.loading === false">
        <button class="btn bg-green" @click="saveData" :disabled="ui.saving">
          <span v-if="ui.saving"
                class="spinner-border spinner-border-sm text-light"
                role="status" aria-hidden="true">
          </span>
          <span class="text-light" v-else>
            Save
          </span>
        </button>
        <button class="btn btn-cancel" @click="$emit('close')">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  name: 'WNineInfoModal',
  props: { agent: Object },
  components: { Multiselect },
  data() {
    return {
      ui: {
        loading: false,
        saving: false
      },
      formData: {
        companyPayee: '',
        tax: '',
        taxId: '',
        SSN: '',
        address: '',
        city: '',
        zip: '',
        state: ''
      },
      states: [],
      taxes: []
    }
  },
  methods: {
    getFormData() {
      this.ui.loading = true
      this.$http.get(`/api/v1/staff/change-wnine-information/${this.agent.id}`)
        .then((res) => {
          this.formData.companyPayee = res.data.companyPayee
          this.formData.tax = res.data.taxClassification
          this.formData.taxId = res.data.taxId
          this.formData.SSN = res.data.ssn
          this.formData.address = res.data.address
          this.formData.city = res.data.city
          this.formData.zip = res.data.zip
          this.formData.state = res.data.state

          this.taxes = res.data.taxClassifications
          this.states = res.data.states
        })
        .catch((err) => {
          this.alertError(err.response.statusText)
        })
        .finally(() => {
          this.ui.loading = false;
        })
    },
    saveData() {
      this.ui.saving = true;
      let formData = new FormData()

      formData.append('companyPayee', this.formData.companyPayee)
      formData.append('taxClassification', this.formData.tax ? this.formData.tax.value : '')
      formData.append('taxId', this.formData.taxId)
      formData.append('ssn', this.formData.SSN)
      formData.append('address', this.formData.address)
      formData.append('city', this.formData.city)
      formData.append('state', this.formData.state ? this.formData.state.value : '')
      formData.append('zip', this.formData.zip)

      this.$http.post(`/api/v1/staff/change-wnine-information/${this.agent.id}`, formData)
        .then((res) => {
          this.alertSuccess(res.data.message)
          this.$emit('success')
          this.$emit('close')
        })
        .catch((err) => {
          this.alertError(err.response.statusText)
        })
        .finally(() => {
          this.ui.saving = false;
        })
    }
  },
  created() {
    this.getFormData()
  }
}
</script>

<style lang="scss" scoped>
.modal-footer {
  button {
    &.bg-green {
      max-width: 110px;
      width: 100%;
    }
  }
}
</style>

<style lang="scss" scoped>
.user-name {
  color: #000000;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
  margin-left: 18px;
}
.user-position {
  opacity: 0.46;
  color: #000000;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
  margin-left: 12px;
}
</style>
