<template>
  <div class="modal">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header">
        <h3>
          MLO Permissions
        </h3>
        <button class="btn btn-control" @click="ui.isEdit = true">
          <img src="@/assets/icons/icon-edit-normal.svg" alt="Edit">
        </button>
      </div>
      <div class="modal-body">
        <div class="text-center mt-3 mb-4" v-if="ui.loading === true">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div :class="{noEdit: ui.isEdit === false}" v-else>
          <div class="modal-body-container">
          <span class="color-light-grey">
            User with this role can manages loans, accounts, expenses, tasks, inbox, and others.
          </span>
         </div>
          <div class="col-6">
           <div class="search-wrapper">
             <img src="@/assets/icons/icon-search.svg" alt="Icon search">
             <input v-model="query" type="text" placeholder="Search">
           </div>
         </div>
          <div class="modal-body-container mt-3" :class="{'border-0': permissions.general === true}">
           <div class="modal-body-container_title">
             General
           </div>
           <div class="form-check form-switch p-0">
             <input class="form-check-input" type="checkbox" id="checkPayOff1" v-model="permissions.general">
             <label class="form-check-label" for="checkPayOff1">General</label>
           </div>
           <div class="table" v-if="permissions.general === true">
             <table class="table">
               <tbody>
               <tr>
                 <td>Statistics</td>
                 <td>
                   <div class="form-check">
                     <input type="checkbox" class="form-check-input" id="fieldView">
                     <label for="fieldView">View</label>
                   </div>
                 </td>
                 <td>
                   <div class="form-check">
                     <input type="checkbox" class="form-check-input" id="fieldAdd">
                     <label for="fieldAdd">Add</label>
                   </div>
                 </td>
                 <td>
                   <div class="form-check">
                     <input type="checkbox" class="form-check-input" id="fieldRemove">
                     <label for="fieldRemove">Remove</label>
                   </div>
                 </td>
                 <td>
                   <div class="form-check">
                     <input type="checkbox" class="form-check-input" id="fieldExport">
                     <label for="fieldExport">Export</label>
                   </div>
                 </td>
               </tr>
               </tbody>
             </table>
           </div>
         </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-start" v-if="ui.loading === false && ui.isEdit === true">
        <button class="btn btn-primary" @click="savePermissions">
          <span v-if="ui.saving === true"
                class="spinner-border spinner-border-sm"
                role="status" aria-hidden="true">
          </span>
          <span v-if="ui.saving === false">Update for this User</span>
        </button>
        <button class="btn btn-cancel ms-3">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MloPermissionsModal',
  data() {
    return {
      query: '',
      permissions: {
        general: false
      },
      ui: {
        loading: false,
        saving: false,
        isEdit: false
      }
    }
  },
  methods: {
    savePermissions() {
      this.ui.saving = true;

      setTimeout(() => {
        this.ui.saving = false;
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
.form-switch {
  input {
    margin: 0;
    &:checked {
      background-color: #266FE5;
    }
  }
}
div.table {
  table {
    tbody {
      tr {
        vertical-align: middle;
        border-top: 0;
        &:not(:first-child) {
          td {
            border-top: 1px solid #E7E8E8;
          }
        }
        &:nth-child(even) {
          background-color: #FFFFFF !important;
        }
        td {
          padding: 16px 0;
          border-top: 0;
          &:first-child {
            padding-left: 0;
            width: 10%;
          }
          &:nth-child(n + 2) {
            width: 5%;
          }
          .form-check {
            margin: 0;
          }
          input {
            height: 18px;
            max-width: 18px;
            border-radius: 50%;

          }
          label {
            margin: 0 0 0 10px;
            opacity: 0.7;
            color: #000000;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 18px;
          }
        }
      }
    }
  }
}
.modal-footer {
  button {
    &.btn-primary {
      max-width: 180px;
      width: 100%;
    }
  }
}
.noEdit {
  cursor: none;
  pointer-events: none;
  opacity: .7;
}
</style>
