import { render, staticRenderFns } from "./UserMetricsModal.vue?vue&type=template&id=08021fd9&scoped=true&"
import script from "./UserMetricsModal.vue?vue&type=script&lang=js&"
export * from "./UserMetricsModal.vue?vue&type=script&lang=js&"
import style0 from "./UserMetricsModal.vue?vue&type=style&index=0&id=08021fd9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08021fd9",
  null
  
)

export default component.exports