<template>
  <div class="modal">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header">
        <h3>
          Payment Method
        </h3>
      </div>
      <div class="modal-body">
        <div class="text-center mt-4" v-if="ui.loading === true">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div v-else>
          <div class="modal-body-container">
            <div class="d-flex align-items-center">
              <v-avatar :username="agent.name" :size="42" rounded />
              <div class="user-name">
                {{ agent.name }}
              </div>
              <div class="user-position">
                {{ agent.position }}
              </div>
            </div>
          </div>
          <div class="modal-body-container border-0">
            <div class="form-group">
              <label for="fieldBankName">
                Bank Name
              </label>
              <input type="text" class="form-control" id="fieldBankName"
                     :class="{'input-filled': formData.bankName.length}"
                     v-model="formData.bankName">
            </div>
            <div class="form-group mt-4">
              <label for="fieldAccountName">
                Account Name
              </label>
              <input type="text" class="form-control" id="fieldAccountName"
                     :class="{'input-filled': formData.accName.length}"
                     v-model="formData.accName">
            </div>
            <div class="form-group mt-4">
              <label for="fieldRoutingName">
                Routing Number
              </label>
              <input type="text" class="form-control" id="fieldRoutingName"
                     :class="{'input-filled': formData.routingName.length}"
                     v-model="formData.routingName">
            </div>
            <div class="form-group mt-4">
              <label for="fieldAccountNumber">
                Account Number
              </label>
              <input type="text" class="form-control" id="fieldAccountNumber"
                     :class="{'input-filled': formData.accNumber.length}"
                     v-model="formData.accNumber">
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-start" v-if="ui.loading === false">
        <button class="btn bg-green" @click="savePayment" :disabled="ui.saving ||
            formData.bankName.length === 0 ||
            formData.accName.length === 0 ||
            formData.routingName.length === 0 ||
            formData.accNumber.length === 0">
          <span v-if="ui.saving"
                class="spinner-border spinner-border-sm text-light"
                role="status" aria-hidden="true">
          </span>
          <span class="text-light" v-else>
            Save
          </span>
        </button>
        <button class="btn btn-cancel ms-4" @click="$emit('close')">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserPaymentModal',
  props: {
    agent: Object,
    payment: Object
  },
  data() {
    return {
      formData: {
        bankName: '',
        accName: '',
        routingName: '',
        accNumber: ''
      },
      ui: {
        loading: false,
        saving: false
      }
    }
  },
  methods: {
    getData() {
      this.ui.loading = true;
      this.formData.bankName = this.payment.bankName
      this.formData.accName = this.payment.accountName
      this.formData.routingName = this.payment.routingNumber
      this.formData.accNumber = this.payment.accountNumber
      setTimeout(() => {
        this.ui.loading = false;
      }, 500)
    },
    savePayment() {
      this.ui.saving = true;
      let formData = new FormData()
      formData.append('bankName', this.formData.bankName)
      formData.append('accountName', this.formData.accName)
      formData.append('routingNumber', this.formData.routingName)
      formData.append('accountNumber', this.formData.accNumber)

      this.$http.post(`/api/v1/staff/change-payment/${this.agent.id}`, formData)
        .then((res) => {
          this.alertSuccess(res.data.message)
          this.$emit('success')
          this.$emit('close')
        })
        .catch((err) => {
          this.alertError(err.response.data ? err.response.data.message : err.response.statusText)
        })
        .finally(() => {
          this.ui.saving = false;
        })
    }
  },
  created() {
    this.getData();
  }
}
</script>

<style lang="scss" scoped>
.user-name {
  color: #000000;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
  margin-left: 18px;
}
.user-position {
  opacity: 0.46;
  color: #000000;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
  margin-left: 12px;
}
.modal-footer {
  button {
    &.bg-green {
      max-width: 110px;
      width: 100%;
    }
  }
}
</style>
