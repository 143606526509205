<template>
  <div class="modal">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header">
        <h3>
          Default Fees
        </h3>
      </div>
      <div id="modal-body-scroll" class="modal-body">
        <div v-if="ui.loading === true" class="text-center mt-5">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div v-else>
          <div class="modal-body-container">
            <div class="modal-body-container_title">
              MLO Fee
            </div>
            <div class="row">
              <div class="col-md-6 form-group">
                <label for="fieldMlo">
                  MLO Percentage
                </label>

                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <div class="input-group-text">%</div>
                  </div>
                  <input
                    id="fieldMlo"
                    v-model="mloPercentage"
                    :class="{ 'input-filled': mlo.percentage.length }"
                    class="form-control"
                    disabled
                    max="99.00"
                    min="0"
                    step=".01"
                    type="number"
                  />
                </div>
              </div>
              <div class="col-md-6 form-group">
                <label for="fieldAmount">
                  Amount
                </label>
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <div class="input-group-text">$</div>
                  </div>
                  <input
                    id="fieldAmount"
                    v-model="mlo.amount"
                    :class="{ 'input-filled': mlo.amount }"
                    class="form-control disabled"
                    disabled
                    type="number"
                  />
                </div>
              </div>
              <div class="col-12 mt-3">
                <span class="notice text-muted">
                  <b>{{ agent.name }}</b> will earn this percent of total
                  origination charged
                </span>
              </div>
            </div>
          </div>
          <div class="modal-body-container">
            <div class="modal-body-container_title">
              Employee Referral
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-group full-width">
                  <label for="fieldEmployeeProcessor">
                    Employee Referral
                  </label>
                  <multiselect
                    id="fieldEmployeeProcessor"
                    v-model="customForm.employeeReferralBonus.agent"
                    :allow-empty="false"
                    :close-on-select="true"
                    :options="customForm.mloBonusReceivers"
                    :searchable="true"
                    :show-labels="false"
                    class="full-width"
                    label="name"
                    open-direction="bottom"
                    placeholder="Select Employee Referral"
                    track-by="id"
                  >
                  </multiselect>
                </div>
                <span
                  v-for="(error, index) in getError(
                    'employeeReferralBonusAgent',
                    errors
                  )"
                  :key="index"
                  class="error-msg"
                >
                  {{ error }}
                </span>
              </div>
              <div class="col-6">
                <div class="form-group full-width">
                  <label for="fieldEmployeeBonus">
                    Bonus
                  </label>
                  <div class="input-group full-width mb-2">
                    <div class="input-group-prepend">
                      <div class="input-group-text">%</div>
                    </div>
                    <input
                      id="fieldEmployeeBonus"
                      v-model="customForm.employeeReferralBonus.bonus"
                      :class="{
                        'input-filled':
                          customForm.employeeReferralBonus.bonus.length
                      }"
                      class="form-control"
                      max="99.00"
                      min="0"
                      required
                      step=".01"
                      type="number"
                    />
                  </div>
                </div>
                <span
                  v-for="(error, index) in getError(
                    'employeeReferralBonus',
                    errors
                  )"
                  :key="index"
                  class="error-msg"
                >
                  {{ error }}
                </span>
              </div>
            </div>
          </div>

          <div
            v-for="(bonus, index) in customForm.mloFee.bonusReceiver"
            :key="index"
            class="modal-body-container pb-3"
          >
            <div class="row">
              <div class="width-sm my-auto">
                <button class="btn btn-control" @click="removeBonus(index)">
                  <img alt="" src="@/assets/icons/icon-delete.svg"/>
                </button>
              </div>
              <div class="col-4">
                <div class="form-group full-width">
                  <label :for="'fieldReceivers' + index">
                    Bonus Receiver
                  </label>
                  <multiselect
                    :id="'fieldReceivers' + index"
                    v-model="bonus.agent"
                    :allow-empty="false"
                    :close-on-select="true"
                    :options="customForm.mloBonusReceivers"
                    :searchable="true"
                    :show-labels="false"
                    class="full-width"
                    label="name"
                    open-direction="bottom"
                    placeholder="Assign to Me"
                    track-by="id"
                  >
                  </multiselect>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label :for="'fieldTypes' + index">
                    Type
                  </label>
                  <multiselect
                    :id="'fieldTypes' + index"
                    v-model="bonus.type"
                    :allow-empty="false"
                    :close-on-select="true"
                    :options="customForm.mloTypes"
                    :searchable="false"
                    :show-labels="false"
                    label="label"
                    open-direction="bottom"
                    placeholder="Select type"
                    track-by="value"
                  >
                  </multiselect>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label :for="'fieldBonus' + index">
                    Bonus
                  </label>
                  <div class="input-group mb-2">
                    <div class="input-group-prepend">
                      <div v-if="bonus.type.label === 'Flat Amount'" class="input-group-text">$</div>
                      <div v-if="bonus.type.label === 'Basis Points'" class="input-group-text">bps</div>
                      <div v-if="bonus.type.label === 'Percentage'" class="input-group-text">%</div>
                    </div>
                    <input :id="'fieldBonus' + index" v-model="bonus.bonus"
                      class="form-control" min="0" step=".01" type="number"/>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label :for="'fieldLabel' + index">Label</label>
                  <input :id="'fieldLabel' + index" v-model="bonus.label"
                    class="form-control" type="text" placeholder="Payroll Tax"/>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 add-bonus">
            <div class="d-flex align-items-center">
              <span class="cursor-pointer" @click="addBonus">
                <img alt="" src="@/assets/icons/bold-icon-sm add blue.svg"/>
                <span class="ps-2">
                  Add Bonus
                </span>
              </span>
            </div>
          </div>

          <div class="modal-body-container mt-4">
            <div class="modal-body-container_title">
              LOA Fee
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-group full-width">
                  <label for="fieldEmployeeLoaProcessor">
                    LOA
                  </label>
                  <multiselect
                    id="fieldEmployeeLoaProcessor"
                    v-model="customForm.loaBonus.agent"
                    :close-on-select="true"
                    :options="customForm.loaBonusReceivers"
                    :searchable="true"
                    :show-labels="false"
                    class="full-width"
                    label="name"
                    open-direction="bottom"
                    placeholder="Select LOA"
                    track-by="id"
                  >
                  </multiselect>
                </div>
                <span
                  v-for="(error, index) in getError('loaAgent', errors)"
                  :key="index"
                  class="error-msg"
                >
                  {{ error }}
                </span>
              </div>
              <div class="col-3">
                <div class="form-group">
                  <label for="fieldEmployeeType">
                    Type
                  </label>
                  <multiselect
                    id="fieldEmployeeType"
                    v-model="customForm.loaBonus.type"
                    :allow-empty="false"
                    :close-on-select="true"
                    :options="customForm.mloTypes"
                    :searchable="false"
                    :show-labels="false"
                    label="label"
                    open-direction="bottom"
                    placeholder="Select type"
                    track-by="value"
                  >
                  </multiselect>
                </div>
              </div>
              <div class="col-3">
                <div class="form-group">
                  <label for="fieldEmployeeLoaBonus">
                    Bonus
                  </label>
                  <div class="input-group mb-2">
                    <div class="input-group-prepend">
                      <div v-if="customForm.loaBonus.type.label === 'Flat Amount'" class="input-group-text">$</div>
                      <div v-if="customForm.loaBonus.type.label === 'Basis Points'" class="input-group-text">bps</div>
                      <div v-if="customForm.loaBonus.type.label === 'Percentage'" class="input-group-text">%</div>
                    </div>
                    <input
                      id="fieldEmployeeLoaBonus"
                      v-model="customForm.loaBonus.bonus"
                      :class="{
                        'input-filled': customForm.loaBonus.bonus.length
                      }"
                      class="form-control"
                      min="0"
                      step=".01"
                      type="number"
                    />
                  </div>
                </div>
                <span v-for="(error, index) in getError('loaBonus', errors)" :key="index" class="error-msg">
                  {{ error }}
                </span>
              </div>
            </div>
          </div>

          <div v-if="mlo.isGranted['isProcessor']" class="modal-body-container">
            <div class="modal-body-container_title">
              Processor
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-group full-width">
                  <label for="fieldProcessorType">
                    Type
                  </label>
                  <multiselect
                    id="fieldProcessorType"
                    v-model="customForm.processorBonus.type"
                    :allow-empty="false"
                    :close-on-select="true"
                    :options="customForm.mloTypes"
                    :searchable="false"
                    :show-labels="false"
                    class="full-width"
                    label="label"
                    open-direction="bottom"
                    placeholder="Select type"
                    track-by="value"
                  >
                  </multiselect>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group full-width">
                  <label for="fieldProcessorBonus">
                    Bonus
                  </label>
                  <div class="input-group full-width mb-2">
                    <div class="input-group-prepend">
                      <div v-if="customForm.processorBonus.type.label === 'Flat Amount'" class="input-group-text">
                        $
                      </div>
                      <div v-if="customForm.processorBonus.type.label === 'Basis Points'" class="input-group-text">
                        bps
                      </div>
                      <div v-if="customForm.processorBonus.type.label === 'Percentage'" class="input-group-text">
                        %
                      </div>
                    </div>
                    <input
                      id="fieldProcessorBonus"
                      v-model="customForm.processorBonus.bonus"
                      :class="{ 'input-filled': customForm.processorBonus.bonus.length}"
                      class="form-control"
                      min="0"
                      step=".01"
                      type="number"
                    />
                  </div>
                </div>
                <span
                  v-for="(error, index) in getError('processorBonus', errors)"
                  :key="index"
                  class="error-msg"
                >
                  {{ error }}
                </span>
              </div>
              <div class="col-12 mt-3">
                <span class="notice text-muted">
                  <b>{{ agent.name }}</b> will earn this bonus after each
                  processed loan funded
                </span>
              </div>
            </div>
          </div>
          <div v-if="mlo.isGranted['isProcessor']" class="modal-body-container">
            <div class="modal-body-container_title">
              Company
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-group full-width">
                  <label for="fieldCompanyType">
                    Type
                  </label>
                  <multiselect
                    id="fieldCompanyType"
                    v-model="customForm.processorBonus.type"
                    :allow-empty="false"
                    :close-on-select="true"
                    :options="customForm.mloTypes"
                    :searchable="false"
                    :show-labels="false"
                    class="full-width"
                    label="label"
                    open-direction="bottom"
                    placeholder="Select type"
                    track-by="value"
                  >
                  </multiselect>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group full-width">
                  <label for="fieldCompanyBonus">
                    Bonus
                  </label>
                  <div class="input-group full-width mb-2">
                    <div class="input-group-prepend">
                      <div v-if="customForm.processorBonus.type.label === 'Flat Amount'" class="input-group-text">$</div>
                      <div v-if="customForm.processorBonus.type.label === 'Basis Points'" class="input-group-text">bps</div>
                      <div v-if="customForm.processorBonus.type.label === 'Percentage'" class="input-group-text">%</div>
                    </div>
                    <input id="fieldCompanyBonus" v-model="customForm.companyBonus.bonus"
                      :class="{ 'input-filled': customForm.companyBonus.bonus.length }"
                      class="form-control" min="0" step=".01" type="number" />
                  </div>
                </div>
                <span v-for="(error, index) in getError('companyBonus', errors)" :key="index" class="error-msg">
                  {{ error }}
                </span>
              </div>
              <div class="col-12 mt-3">
                <span class="notice text-muted">
                  <b>CMC</b> receives this bonus after each loan that was
                  processed by <b>{{ agent.name }}</b>
                </span>
              </div>
            </div>
          </div>
          <div class="modal-body-container border-0 mt-5">
            <div class="modal-body-container_title">
              Summary
              <span class="cursor-pointer">
                <img v-tippy="{ placement: 'right', arrow: true, arrowType: 'round' }"
                  :content="`Example: Loan amount = ${convertToCurrency(loanAmount)} <br> Origination = ${convertToCurrency(origination)}`"
                  alt="alert"
                  src="@/assets/icons/No Requested Docs.svg"
                />
              </span>
            </div>
            <div class="table">
              <table class="table">
                <thead>
                <tr>
                  <th>Payment Split</th>
                  <th></th>
                  <th>Share</th>
                  <th>Amount</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(summary, index) in getSummary" :key="index">
                  <td>{{ summary.name }}</td>
                  <td>
                      <span class="color-light-grey">
                        {{ summary.position }}
                      </span>
                  </td>
                  <td v-if="summary.type === 'percentage'">
                    {{ summary.bonus }}%
                  </td>
                  <td v-else-if="summary.type === 'basis_points'">
                    {{ summary.bonus }}bps
                  </td>
                  <td v-else>
                    Fixed
                    <span class="color-light-grey">
                        {{ summary.bonus }}
                    </span>
                  </td>
                  <td>{{ convertToCurrency(summary.amount) }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-start">
        <button :disabled="ui.saving" class="btn bg-green" @click="save">
          <span
            v-if="ui.saving"
            aria-hidden="true"
            class="spinner-border spinner-border-sm"
            role="status"
          >
          </span>
          <span v-else>Save</span>
        </button>
        <button class="btn btn-cancel ms-3" @click="$emit('close')">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import PerfectScrollbar from "perfect-scrollbar";

export default {
  name: "DefaultFeesModal",
  components: {Multiselect},
  props: {agent: Object},
  data() {
    return {
      loanAmount: 500000,
      origination: 5000,
      mlo: {
        percentage: 0,
        amount: 0,
        isGranted: Object
      },
      ui: {
        loading: false,
        saving: false,
        ps: null
      },
      customForm: {
        processorBonus: {
          type: {label: "Percentage", value: "percentage"},
          bonus: ""
        },
        companyBonus: {
          type: {label: "Percentage", value: "percentage"},
          bonus: ""
        },
        employeeReferralBonus: {
          agent: "",
          bonus: ""
        },
        loaBonus: {
          agent: "",
          type: {label: "Percentage", value: "percentage"},
          bonus: ""
        },
        mloFee: {
          bonusReceiver: []
        },
        mloBonusReceivers: [],
        loaBonusReceivers: [],
        mloTypes: []
      },
      errors: []
    };
  },
  methods: {
    getData() {
      this.ui.loading = true;
      this.$http
        .get(`/api/v1/user/fee/edit/${this.agent.id}`)
        .then(res => {
          this.mlo.isGranted = res.data.isGranted;
          this.customForm.mloTypes = res.data.mloTypes;
          this.customForm.loaBonusReceivers = res.data.loas;
          this.customForm.mloBonusReceivers = res.data.agents;
          this.customForm.companyBonusReceivers = res.data.companies;
          this.customForm.mloBonusReceivers.unshift(res.data.companies[0]);

          /** Set Edit form Data */
          this.customForm.mloFee.bonusReceiver = res.data.mloBonus;

          if (Object.keys(res.data.loaBonus).length > 0 || res.data.loaBonus.length > 0) {
            this.customForm.loaBonus = res.data.loaBonus;
          }

          if (Object.keys(res.data.processorBonus).length > 0 || res.data.processorBonus.length > 0) {
            this.customForm.processorBonus = res.data.processorBonus;
          }

          if (Object.keys(res.data.cmcBonus).length > 0 || res.data.cmcBonus.length > 0) {
            this.customForm.companyBonus = res.data.cmcBonus;
          }

          if (Object.keys(res.data.referralBonus).length > 0 || res.data.referralBonus.length > 0) {
            this.customForm.employeeReferralBonus = res.data.referralBonus;
          } else {
            this.customForm.employeeReferralBonus.agent = this.customForm.mloBonusReceivers[0];
          }

          // MLO Bonus Calculation
          this.mloBonusCalculation();
        })
        .catch((err) => {
          this.alertError(err.response.statusText);
        })
        .finally(() => {
          this.ui.loading = false;
        });
    },

    // Add Bonus start
    addBonus() {
      if (this.customForm.mloFee.bonusReceiver.length) {
        let countMloBonuses = this.customForm.mloFee.bonusReceiver[this.customForm.mloFee.bonusReceiver.length - 1];
        this.customForm.mloFee.bonusReceiver.push({
          id: countMloBonuses.id + 1,
          agent: this.customForm.mloBonusReceivers[0],
          type: this.customForm.mloTypes[0],
          bonus: "15.00",
          label: ''
        });
      } else {
        this.customForm.mloFee.bonusReceiver.push({
          id: 1,
          agent: this.customForm.mloBonusReceivers[0],
          type: this.customForm.mloTypes[0],
          bonus: "15.00",
          label: ''
        });
      }
    },
    // Add Bonus end

    removeBonus(index) {
      this.customForm.mloFee.bonusReceiver.splice(index, 1);
    },

    save() {
      this.ui.saving = true;
      this.errors = [];

      let formData = new FormData();
      if (this.customForm.loaBonus.agent) {
        formData.append("loaAgent", this.customForm.loaBonus.agent.id);
        formData.append("loaType", this.customForm.loaBonus.type.value);
        formData.append("loaBonus", this.customForm.loaBonus.bonus);
      }

      formData.append(
        "employeeReferralBonus",
        this.customForm.employeeReferralBonus.bonus
      );
      formData.append(
        "employeeReferralBonusAgent",
        this.customForm.employeeReferralBonus.agent.id
      );

      if (this.mlo.isGranted["isProcessor"]) {
        formData.append("companyBonus", this.customForm.companyBonus.bonus);
        formData.append("processorBonus", this.customForm.processorBonus.bonus);
        formData.append("companyBonusType", this.customForm.companyBonus.type.value);
        formData.append("processorBonusType", this.customForm.processorBonus.type.value);
      }

      this.customForm.mloFee.bonusReceiver.forEach((value, index) => {
        formData.append(`mloFee[${index}][agent]`, value.agent.id);
        formData.append(`mloFee[${index}][bonus]`, value.bonus);
        formData.append(`mloFee[${index}][type]`, value.type.value);
        formData.append(`mloFee[${index}][label]`, value.label);
      });

      this.$http
        .post(`/api/v1/user/fee/edit/${this.agent.id}`, formData)
        .then(res => {
          this.alertSuccess(res.data.message);
          this.$emit("success");
          this.$emit("close");
        })
        .catch(err => {
          if (err.response.data.errors || err.response.status === 400) {
            this.errors = err.response.data.errors;
            this.alertError(err.response.data.message);
          } else if (err.response.status === 502) {
            this.alertError(err.response.data.message);
          } else {
            this.alertError(err.response.statusText);
          }
        })
        .finally(() => {
          this.ui.saving = false;
        });
    },

    // Bonus Calculation start
    mloBonusCalculation() {
      let origination = parseFloat(this.origination);
      let percentage = parseFloat(0);

      // Set Employee Referral Bonus
      if (this.customForm.employeeReferralBonus.agent && this.customForm.employeeReferralBonus.bonus > 0) {
        percentage += parseFloat(this.customForm.employeeReferralBonus.bonus);
      }

      if (this.customForm.loaBonus.agent && this.customForm.loaBonus.bonus > 0) {
        if (this.customForm.loaBonus.type.value === "percentage") {
          percentage += parseFloat(this.customForm.loaBonus.bonus);
        }
      }

      this.customForm.mloFee.bonusReceiver.forEach(value => {
        if (value.agent && value.bonus > 0) {
          if (value.type.value === "percentage") {
            percentage += parseFloat(value.bonus);
          }
        }
      });

      this.mlo.percentage = parseFloat(100 - percentage);
      this.mlo.amount = parseFloat(origination * (this.mlo.percentage / 100));
      return this.mlo.percentage;
    },
    getAmountByPer(percentage) {
      let origination = parseFloat(this.origination);
      percentage = parseFloat(percentage);
      return parseFloat(origination * (percentage / 100)).toFixed(2);
    },
    getPerByAmount(amount) {
      let origination = parseFloat(this.origination);
      amount = parseFloat(amount);
      return parseFloat((amount / origination) * 100).toFixed(2);
    },
    getAmountByBPS(bps) {
      let origination = parseFloat(this.loanAmount);
      bps = parseFloat(bps);
      return parseFloat(origination * (bps / 10000));
    },
    getBPSByAmount(amount) {
      let origination = parseFloat(this.loanAmount);
      amount = parseFloat(amount);
      return parseFloat((amount / origination) * 10000);
    },
    calculateSummary() {
      let summary = [];
      let hireBonusesTotal = 0;
      let branchId = 0

      summary.push({
        name: this.agent.name,
        position: this.agent.position,
        type: "percentage",
        bonus: this.mlo.percentage,
        amount: this.getAmountByPer(this.mlo.percentage)
      });

      if (this.customForm.employeeReferralBonus.agent && this.customForm.employeeReferralBonus.bonus > 0) {
        summary.push({
          name: this.customForm.employeeReferralBonus.agent.name,
          position: this.customForm.employeeReferralBonus.agent.position,
          type: "percentage",
          bonus: this.customForm.employeeReferralBonus.bonus,
          amount: this.getAmountByPer(this.customForm.employeeReferralBonus.bonus)
        });
      }

      this.customForm.mloFee.bonusReceiver.forEach(value => {
        branchId = value.agent.branch_id;

        let amount = this.getAmount(value.type.value, value.bonus);
        let bonus = this.getBonus(value.type.value, amount);

        if (value.agent && value.bonus > 0) {
          let mloContent = {
            name: value.agent.name,
            position: value.agent.position,
            type: value.type.value,
            bonus: bonus,
            amount: amount
          };

          if (mloContent.type !== "percentage") {
            hireBonusesTotal += parseFloat(mloContent.amount);
          }

          summary.push(mloContent);
        }
      });

      if (this.customForm.loaBonus.agent && this.customForm.loaBonus.bonus > 0) {
        let amount = this.getAmount(this.customForm.loaBonus.type.value, this.customForm.loaBonus.bonus);
        let bonus = this.getBonus(this.customForm.loaBonus.type.value, amount);
        let content = {
          name: this.customForm.loaBonus.agent.name,
          position: this.customForm.loaBonus.agent.position,
          type: this.customForm.loaBonus.type.value,
          bonus: bonus,
          amount: amount
        };

        if (content.type !== "percentage") {
          if (branchId === 14) {
            summary[2]["amount"] -= parseFloat(content.amount);
          } else {
            hireBonusesTotal += parseFloat(content.amount);
          }
        }

        summary.push(content);
      }

      let procBonus = this.customForm.processorBonus
      if (procBonus && procBonus.bonus > 0) {
        let procBonus = this.customForm.processorBonus
        let amount = this.getAmount(procBonus.type.value, procBonus.bonus);
        let bonus = this.getBonus(procBonus.type.value, amount);

        let content = {
          name: 'Processor',
          position: '',
          type: procBonus.type.value,
          bonus: bonus,
          amount: amount
        }

        if (content.type !== "percentage") {
          hireBonusesTotal += parseFloat(content.amount);
        }

        summary.push(content);
      }

      let cmcBonus = this.customForm.companyBonus
      if (cmcBonus && cmcBonus.bonus > 0) {
        let amount = this.getAmount(cmcBonus.type.value, cmcBonus.bonus);
        let bonus = this.getBonus(cmcBonus.type.value, amount);

        let content = {
          name: 'Company',
          position: '',
          type: cmcBonus.type.value,
          bonus: bonus,
          amount: amount
        }

        if (content.type !== "percentage") {
          hireBonusesTotal += parseFloat(content.amount);
        }

        summary.push(content);
      }

      if (hireBonusesTotal) {
        if (summary[0]['bonus'] > parseFloat(summary[1]['bonus'])) {
          summary[0]["amount"] = parseFloat(summary[0]["amount"] - hireBonusesTotal);
        } else {
          summary[1]["amount"] = parseFloat(summary[1]["amount"] - hireBonusesTotal);
        }
      }

      return summary;
    },
    // Bonus Calculation End
    getAmount(type, bonus) {
      if (type === 'percentage') {
        return this.getAmountByPer(bonus)
      }

      if (type === 'basis_points') {
        return this.getAmountByBPS(bonus)
      }

      return bonus
    },
    getBonus(type, amount) {
      if (type === 'percentage') {
        return this.getPerByAmount(amount)
      }

      if (type === 'basis_points') {
        return this.getBPSByAmount(amount)
      }

      return amount
    }
  },
  created() {
    this.getData();
  },
  mounted() {
    const scrollbar = document.getElementById("modal-body-scroll");
    this.ui.ps = new PerfectScrollbar(scrollbar);
  },
  computed: {
    mloPercentage: function () {
      return this.mloBonusCalculation();
    },
    getSummary: function () {
      return this.calculateSummary();
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-block {
  width: 45% !important;

  .width-sm {
    width: 6.333% !important;
  }
}

.modal-footer {
  button {
    &.bg-green {
      max-width: 110px;
      width: 100%;
    }
  }
}

.modal-body-container::v-deep .multiselect {
  &.multiselect--active {
    .multiselect__tags {
      transition: all 0.3s;
      border: 1px solid rgba(38, 111, 229, 0.5);
      box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
    }
  }

  &__tags {
    padding: 8px 40px 0 8px;
    transition: all 0.3s;

    &:hover {
      border: 1px solid rgba(38, 111, 229, 0.2);
      box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
    }
  }

  &__select {
    &:before {
      top: 15%;
      position: relative;
      right: 0;
      color: #999;
      border: solid rgba(0, 0, 0, 0.46);
      margin-top: 4px;
      border-width: 0px 1px 1px 0;
      display: inline-block;
      padding: 3px;
      content: "";
      transform: rotate(44deg);
    }
  }

  &__single {
    color: rgba(0, 0, 0, 0.46);
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }
  }

  &__option {
    padding: 10px 12px;
    min-height: 36px;
    transition: all 0.3s;
    line-height: 20px;
    font-size: 14px;
    color: #000;
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }

    &--highlight {
      background: #f3f3f3;
      line-height: 20px;
      font-size: 14px;
      color: #000;
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }
    }
  }
}
</style>
