<template>
  <div class="modal">
    <div class="modal-bg" @click="$emit('close')"></div>

    <!--  Preload modal spinner  -->
    <modal-spinner v-if="ui.loading === true" />

    <div class="modal-block" v-else>
      <div class="modal-header">
        <h3>Compensation</h3>
      </div>
      <div class="modal-body">
        <div class="modal-title">Compensation/Margin for each lead source</div>
        <div class="modal-body-container" v-for="(plan, key) in plans" :key="key">
          <div class="row">
            <div class="col-3">
              <div class="form-group">
                <label for="name">Name</label>
                <input type="text" class="form-control" id="name" placeholder="Bankrate" autocomplete="off"
                       v-model="plan.name" v-input-filled="plan.name">
              </div>
            </div>
            <div class="col-3">
              <div class="form-group">
                <label for="comp">Compensation</label>
                <div class="input-with-icon">
                  <span class="icon">%</span>
                  <input type="number" class="form-control" id="comp" placeholder="1.5"
                         v-model="plan.comp" v-input-filled="plan.comp">
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="form-group">
                <label for="minComp">Min. compensation</label>
                <div class="input-with-icon">
                  <span class="icon">$</span>
                  <input type="number" class="form-control" id="minComp" placeholder="7500"
                         v-model="plan.minComp" v-input-filled="plan.minComp">
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="form-group">
                <label for="maxComp">Max. compensation</label>
                <div class="input-with-icon">
                  <span class="icon">$</span>
                  <input type="number" class="form-control" id="maxComp" placeholder="9500"
                         v-model="plan.maxComp" v-input-filled="plan.maxComp">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-start">
        <button class="btn bg-green" @click="saveData">
          <span v-if="ui.saving === true" aria-hidden="true" class="spinner-border spinner-border-sm text-light" role="status"></span>
          <span v-else>Save</span>
        </button>
        <button class="btn btn-cancel ml-3" @click="$emit('close')">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ModalSpinner from "../../../components/modal/ModalSpinner";
export default {
  name: 'AgentCompensationModal',
  components: {ModalSpinner},
  props: {
    partners: {type: Array, required: true},
    isRefi: { type: Boolean }
  },
  data() {
    return {
      ui: {
        loading: false,
        saving: false
      },
      plans: [],
      emptyPlan: {
        name: null,
        comp: null,
        minComp: null,
        maxComp: null,
      },
    }
  },
  methods: {
    saveData() {
      this.ui.saving = true;

      this.plans.forEach((plan) => {
        let url = plan.id
          ? `/api/v1/corr-pricer/${plan.id}/update`
          : `/api/v1/corr-pricer/${this.$route.params.id}/new-partner`;

        this.$http.post(url, {
          name: plan.name,
          comp: plan.comp,
          minComp: plan.minComp,
          maxComp: plan.maxComp,
          tag: plan.tag
        }).catch((err) => {
            this.alertError(err.response.data ? err.response.data.message : err.response.statusText);
          })
          .finally(() => this.ui.saving = false)
      })

      this.$emit('close', true)
    }
  },
  beforeMount() {
    if (this.partners.length === 0) {
      this.plans.push(Object.assign({}, this.emptyPlan))
      if (!this.isRefi) {
        this.plans.push(Object.assign({}, this.emptyPlan))
        this.plans.push(Object.assign({}, this.emptyPlan))
      }
    } else {
      this.plans = this.partners
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-block {
  .modal-title {
    color: rgba(0,0,0,.46);
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    padding-bottom: 14px;
    border-bottom: 1px solid #E7E8E8;
    margin-bottom: 24px;
  }
  .modal-footer {
    button {
      &.bg-green {
        max-width: 110px!important;
        width: 100%;
      }
    }
  }
}
</style>
