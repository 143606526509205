<template>
  <div class="modal">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header">
        <h3>
          Restrictions
        </h3>
      </div>
      <div class="modal-body">
        <div class="text-center mt-5" v-if="ui.loading">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div class="modal-body-container border-0" v-else>
          <div class="col-6 form-group">
            <label for="fieldWReserved">
              Withdrawal Reserved
            </label>
            <input type="number" class="form-control" id="fieldWReserved"
                   :class="{'input-filled': formData.wReserved.length}"
                   v-model="formData.wReserved">
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-start">
        <button @click="update"
                class="btn bg-green"
                :disabled="ui.saving || formData.wReserved < 0">
          <span v-if="ui.saving"
                class="spinner-border spinner-border-sm"
                role="status" aria-hidden="true">
          </span>
          <span v-else>Save</span>
        </button>
        <button class="btn btn-cancel ms-3" @click="$emit('close')">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RestrictionsModal",
  props: {
    agent: Object,
    restrictions: Object
  },
  data() {
    return {
      formData: {
        wReserved: 0,
      },
      ui: {
        loading: false,
        saving: false
      }
    }
  },
  methods: {
    getData() {
      this.ui.loading = true;
      this.formData.wReserved = this.restrictions.wReserved
      setTimeout(() => {
        this.ui.loading = false;
      }, 500)
    },
    update() {
      this.ui.saving = true;
      let formData = new FormData()
      formData.append('wReserved', this.formData.wReserved)

      this.$http.post(`/api/v1/staff/change-restrictions/${this.agent.id}`, formData)
        .then((res) => {
          this.alertSuccess(res.data.message)
          this.$emit('success')
          this.$emit('close')
        })
        .catch((err) => {
          this.alertError(err.response.statusText)
        })
        .finally(() => {
          this.ui.saving = false;
        })
    }
  },
  created() {
    this.getData();
  }
}
</script>

<style lang="scss" scoped>
.modal-footer {
  button {
    &.bg-green {
      max-width: 110px;
      width: 100%;
    }
  }
}
</style>
