<template>
  <div class="modal">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header">
        <h3>
          Loans Limit
        </h3>
      </div>
      <div class="modal-body">
        <div class="text-center mt-5" v-if="ui.loading === true">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div class="modal-body-container border-0" v-else>
          <div class="col-6 form-group">
            <label for="fieldMaxPerDay">
              Max loans per day
            </label>
            <input type="number" class="form-control" id="fieldMaxPerDay"
                   :class="{'input-filled': formData.maxPerDay.length}"
                   v-model="formData.maxPerDay">
          </div>
          <div class="col-6 form-group mt-4">
            <label for="fieldTotal">
              Max loans total
            </label>
            <input type="number" class="form-control" id="fieldTotal"
                   :class="{'input-filled': formData.maxTotal.length}"
                   v-model="formData.maxTotal">
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-start">
        <button @click="updateLimit"
                class="btn bg-green"
                :disabled="ui.saving || formData.maxPerDay === 0 || formData.maxTotal === 0">
          <span v-if="ui.saving"
                class="spinner-border spinner-border-sm"
                role="status" aria-hidden="true">
          </span>
          <span v-else>Save</span>
        </button>
        <button class="btn btn-cancel ms-3" @click="$emit('close')">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoansLimitModal",
  props: {
    agent: Object,
    loansLimit: Object
  },
  data() {
    return {
      formData: {
        maxPerDay: 0,
        maxTotal: 0
      },
      ui: {
        loading: false,
        saving: false
      }
    }
  },
  methods: {
    getData() {
      this.ui.loading = true;
      this.formData.maxPerDay = this.loansLimit.maxPerDay
      this.formData.maxTotal = this.loansLimit.maxTotal
      setTimeout(() => {
        this.ui.loading = false;
      }, 500)
    },
    updateLimit() {
      this.ui.saving = true;
      let formData = new FormData()
      formData.append('maxPerDay', this.formData.maxPerDay)
      formData.append('maxTotal', this.formData.maxTotal)

      this.$http.post(`/api/v1/processor/limit/${this.agent.id}`, formData)
        .then((res) => {
          this.alertSuccess(res.data.message)
          this.$emit('success')
          this.$emit('close')
        })
        .catch((err) => {
          this.alertError(err.response.statusText)
        })
        .finally(() => {
          this.ui.saving = false;
        })
    }
  },
  created() {
    this.getData();
  }
}
</script>

<style lang="scss" scoped>
.modal-footer {
  button {
    &.bg-green {
      max-width: 110px;
      width: 100%;
    }
  }
}
</style>
