<template>
  <div class="modal" id="EditUserModal">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header">
        <h3>{{ note.id > 0 ? "Edit" : "Create" }} Note</h3>
      </div>
      <div class="modal-body" id="modal-body-scroll">
        <div class="modal-body-container">
          <div class="col-12">
            <div class="form-group full-width">
              <label for="fieldContent">Note</label>
              <ckeditor
                :editor="editor"
                v-model="note.description"
                id="fieldContent"
                :config="editorConfig"
              >
              </ckeditor>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal-footer d-flex justify-content-start"
        v-if="ui.loading === false"
      >
        <button
          type="submit"
          @click="saveData"
          class="btn bg-primary submit"
          :disabled="ui.saving || note.description.trim() === ''"
        >
          <span
            v-if="ui.saving"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          >
          </span>
          <span v-if="!ui.saving">Save</span>
        </button>
        <button
          type="reset"
          class="btn btn-cancel ms-4"
          @click="$emit('close')"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import PerfectScrollbar from "perfect-scrollbar";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  name: "UserNoteModal",
  props: {
    noteData: {
      type: Object,
    },
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {},
      ui: {
        saving: false,
        loading: false,
        ps: null,
      },
      note: this.noteData,
    };
  },
  methods: {
    saveData() {
      this.ui.saving = true;

      let formData = new FormData();
      formData.append("note", this.note.description.trim());
      let url = `/api/v1/user/note/${this.$route.params.id}/new`;
      if (this.note.id > 0) {
        url = `/api/v1/user/note/${this.note.id}/edit`;
      }
      this.$http
        .post(url, formData)
        .then((res) => {
          if (res.data.status === "success") {
            this.alertSuccess(res.data.message);
            this.$emit("success");
            this.$emit("close");
          } else {
            this.alertError(res.data.message);
          }
        })
        .catch((err) => this.alertError(err.response.statusText))
        .finally(() => (this.ui.saving = false));
    },
  },
  mounted() {
    const scrollbar = document.getElementById("modal-body-scroll");
    this.ui.ps = new PerfectScrollbar(scrollbar);
  },
};
</script>

<style lang="scss" scoped>
#modal-body-scroll {
  position: relative;
  height: calc(100% - 100px);
}
.ck-editor .ck-editor__main .ck-content {
  min-height: 200px !important;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
</style>
