import { render, staticRenderFns } from "./ReviewModal.vue?vue&type=template&id=5957d626&scoped=true&"
import script from "./ReviewModal.vue?vue&type=script&lang=js&"
export * from "./ReviewModal.vue?vue&type=script&lang=js&"
import style0 from "./ReviewModal.vue?vue&type=style&index=0&id=5957d626&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5957d626",
  null
  
)

export default component.exports