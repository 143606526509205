<template>
  <div class="modal">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header">
        <h3>
          Who can assign loans to ‘{{ agent.name }}’?
        </h3>
      </div>
      <div class="modal-body" id="modal-body-scroll">
        <div class="text-center mt-4" v-if="ui.loading === true">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div v-else>
          <div class="col-6">
            <div class="search-wrapper">
              <img src="@/assets/icons/icon-search.svg" alt="Icon search">
              <input v-model="query" type="text" placeholder="Search">
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <div class="selected-users">
              Users Selected ({{ selectedCount }})
            </div>
            <button class="btn btn-link" @click="$emit('createUser')">
              Create User
            </button>
          </div>
          <div class="row mt-4 users-list w-100 m-auto">
            <div class="col-12 users-list_item" v-for="user in userData" :key="user.id">
              <div class="row cursor-pointer" @click="user.isChecked = !user.isChecked; checkedCount(user.isChecked)">
                <div class="col-8">
                  <div class="d-flex align-items-center">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" :id="'user' + user.id" v-model="user.isChecked">
                    </div>
                    <v-avatar :src="agentPhoto(user.profileImage)" :size="42" rounded />
                    <span class="users-list_item--title">
                    {{ user.name }}
                  </span>
                  </div>
                </div>
                <div class="col-4 text-end my-auto">
                <span class="users-list_item--position">
                  {{ user.position }}
                </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-start">
        <button @click="changeAssignee"
                class="btn bg-green"
                :disabled="ui.saving">
          <span v-if="ui.saving"
                class="spinner-border spinner-border-sm"
                role="status" aria-hidden="true">
          </span>
          <span v-else>Save</span>
        </button>
        <button class="btn btn-cancel ms-4" @click="$emit('close')">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar';

export default {
  name: 'AssignLoanModal',
  props: {
    agent: Object,
    assignee: Array,
    allAgents: Array
  },
  data() {
    return {
      query: '',
      users: [],
      ui: {
        loading: false,
        saving: false,
        ps: null
      },
      selectedCount: 0
    }
  },
  methods: {
    getData() {
      this.ui.loading = true;
      this.users = this.allAgents;
      this.users.map((el) => {
        el.isChecked = false;
        this.assignee.forEach((agent) => {
          if (agent.id === el.id) {
            el.isChecked = true;
            this.checkedCount(true);
          }
        });
      });

      setTimeout(() => {
        this.ui.loading= false;
      }, 500)
    },
    checkedCount(val) {
      if (val === true) {
        this.selectedCount++;
      } else {
        this.selectedCount--;
      }
    },
    changeAssignee() {
      this.ui.saving = true;
      let formData = new FormData();

      this.users.forEach((agent) => {
        if (agent.isChecked === true) {
          formData.append('agents[]', agent.id)
        }
      });

      this.$http.post(`/api/v1/processor/assignee/${this.agent.id}`, formData)
        .then((res) => {
          this.alertSuccess(res.data.message)
          this.$emit('success')
          this.$emit('close')
        })
        .catch((err) => {
          this.alertError(err.response.statusText)
        })
        .finally(() => {
          this.ui.saving = false;
        })
    }
  },
  created() {
    this.getData();
  },
  computed: {
    userData() {
      return this.users.filter(user => {
        return user.name.toLowerCase().includes(this.query.toLowerCase())
      })
    }
  },
  mounted() {
    const scrollbar = document.getElementById('modal-body-scroll');
    this.ui.ps = new PerfectScrollbar(scrollbar);
  }
}
</script>

<style lang="scss" scoped>
#modal-body-scroll {
  position: relative;
  height: calc(100% - 100px);
}
.form-check {
  margin-right: 14px;
  input {
    height: 18px;
    width: 18px;
    max-width: 18px;
    &:checked {
      background-color: #0d6efd;
      border-color: #0d6efd;
    }
  }
}

.users-list {
  &_item {
    padding: 12px 0;
    border-bottom: 1px solid #E7E8E8;

    &--title {
      color: #000000;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 21px;
      margin-left: 18px;
    }

    &--position {
      opacity: 0.46;
      color: #000000;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 18px;
      text-align: right;
    }
  }
}

.selected-users {
  opacity: 0.46;
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
}

button {
  &.btn-link {
    color: #017AFF;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
    font-weight: normal;
    padding: 0;
  }
  &.bg-green {
    max-width: 110px;
    width: 100%;
  }
}
</style>
