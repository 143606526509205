<template>
  <div class="modal modal-small-center box-shadow-light border-small" id="userUploadDocuments">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header border-0">
        <h3>
          Upload Document
        </h3>
        <img alt="Close" class="cursor-pointer" src="@/assets/icons/icon-remove.svg" @click="$emit('close')">
      </div>
      <div class="modal-body">
        <div class="modal-body-container border-0 m-0">
          <div class="form-group full-width">
            <label for="fieldName">
              Name
            </label>
            <input type="text" class="form-control" id="fieldName" v-model="formData.name" placeholder="Enter document name"
                   :class="{'input-filled': formData.name.length}">
          </div>
          <div class="form-group full-width mt-4">
            <label for="fieldType">
              Select Type
            </label>
            <multiselect id="fieldType"
                         track-by="value" label="label"
                         v-model="formData.selectType"
                         :close-on-select="true"
                         :options="types"
                         :searchable="true"
                         :show-labels="false"
                         :allow-empty="false"
                         class="full-width"
                         open-direction="bottom"
                         placeholder="Select Type">
            </multiselect>
          </div>
          <div class="form-group full-width mt-4" v-if="formData.selectType.value === 'Borrower'">
            <label for="fieldBorrower">
              Borrower Name
            </label>
            <input type="text" class="form-control" id="fieldBorrower" v-model="formData.borrower" placeholder="Enter borrower name"
                   :class="{'input-filled': formData.borrower.length}">
          </div>
          <file-field :field-id="'fieldId'" :files="formData.files" :multiple="true" @change="onFileChange" class="mt-4"/>
          <div class="d-flex mt-4">
            <button class="btn bg-green w-100" @click="saveDocuments" :disabled="ui.saving || formData.files.length === 0 || formData.name.length === 0 || (formData.selectType.value === 'Borrower' && formData.borrower.length === 0)">
                <span v-if="ui.saving"
                      aria-hidden="true"
                      class="spinner-border spinner-border-sm text-light" role="status">
                </span>
                <span v-else class="text-light">
                 Upload Document
                </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import FileField from "../../../components/form/FileField";

export default {
  name: 'UserUploadDocumentsModal',
  props: {
    userId: Number,
    types: Array
  },
  components: {FileField, Multiselect},
  data() {
    return {
      formValue: new FormData(),
      formData: {
        files: [],
        selectType: this.types[0],
        name: '',
        borrower: ''
      },
      ui: {
        saving: false
      }
    }
  },
  methods: {
    onFileChange(e) {
      let length = e.target.files.length
      for(let i = 0; i < length; i++) {
        this.formData.files.push(e.target.files[i])
        this.formValue.append('files[]', e.target.files[i])
      }
    },
    saveDocuments() {
      this.ui.saving = true;
      this.formValue.append('type', this.formData.selectType.value)
      this.formValue.append('name', this.formData.name)
      this.formValue.append('borrower', this.formData.borrower)
      this.formValue.append('agent', this.userId)

      this.$http.post(`/api/v1/documents/new`, this.formValue)
        .then((res) => {
          this.alertSuccess(res.data.message)
          this.$emit('success')
          this.$emit('close')
        })
        .catch((err) => {
          this.alertError(err.response.statusText)
        })
        .finally(() => {
          this.ui.saving = false;
        })
    }
  }
}
</script>

<style lang="scss" scoped>
#userUploadDocuments {
  .modal-header {
    position: relative;
  }

  .modal-body {
    padding: 0 30px;

  }
}
</style>
