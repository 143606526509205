<template>
  <div class="modal modal-small-center box-shadow-light border-small" id="changePasswordModal">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header border-0">
        <h3>
          Change Password
        </h3>
        <img src="@/assets/icons/icon-remove.svg" class="cursor-pointer" alt="Close" @click="$emit('close')">
      </div>
      <div class="modal-body">
        <div class="text-center mt-3 mb-4" v-if="ui.loading === true">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div class="modal-body-container border-0 mb-0 pb-4" v-else>
          <div class="col-12">
            <div class="form-group full-width">
              <label for="fieldOldPass">
                Current Password
              </label>
              <div class="position-relative input-icon">
                <img class="cursor-pointer" src="@/assets/icons/icon-Show password.svg" @click="switchVisibilityOld" alt="Show pass">
                <input class="form-control" id="fieldOldPass" autocomplete="off"
                       v-model="formData.oldPassword"
                       :type="oldPasswordFieldType">
              </div>
            </div>
            <span class="error-msg" v-for="(error, index) in getError('oldPassword', errors)" :key="index">
              {{ error }}
            </span>
          </div>
          <div class="col-12 mt-4">
            <div class="form-group full-width">
              <label for="fieldPass">
                New Password
              </label>
              <div class="position-relative input-icon">
                <img class="cursor-pointer" src="@/assets/icons/icon-Show password.svg" @click="switchVisibility" alt="Show pass">
                <input class="form-control" id="fieldPass" autocomplete="off"
                       v-model="formData.newPassword"
                       :type="newPasswordFieldType">
              </div>
            </div>
            <span class="error-msg" v-for="(error, index) in getError('newPassword', errors)" :key="index">
              {{ error }}
            </span>
          </div>
          <div class="col-12 mt-4">
            <div class="form-group full-width">
              <label for="fieldConfirmPass">
                Confirm Password
              </label>
              <div class="position-relative input-icon">
                <img class="cursor-pointer" src="@/assets/icons/icon-Show password.svg" @click="switchVisibilityConfirm" alt="Show pass">
                <input class="form-control" id="fieldConfirmPass" autocomplete="off"
                       :type="confirmPasswordFieldType"
                       v-model="formData.confirmPassword">
              </div>
            </div>
            <span class="error-msg" v-for="(error, index) in getError('repeatNewPassword', errors)" :key="index">
              {{ error }}
            </span>
          </div>
          <div class="d-flex mt-4">
            <button class="btn bg-green"
              @click="savePass"
              :disabled="(formData.newPassword.trim() !== formData.confirmPassword.trim() || formData.oldPassword.trim() == '') || ui.saving">
             <span v-if="ui.saving === true"
                   class="spinner-border spinner-border-sm"
                   role="status" aria-hidden="true">
              </span>
              <span v-if="ui.saving === false">Change Password</span>
            </button>
            <button class="btn btn-cancel ms-3" @click="$emit('close')">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChangePasswordModal',
  props: {
    userId: Number
  },
  data() {
    return {
      newPasswordFieldType: 'password',
      oldPasswordFieldType: 'password',
      confirmPasswordFieldType: 'password',
      formData: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      ui: {
        loading: false,
        saving: false
      },
      errors: []
    }
  },
  methods: {
    switchVisibility() {
      this.newPasswordFieldType = this.newPasswordFieldType === 'password' ? 'text' : 'password'
    },
    switchVisibilityConfirm() {
      this.confirmPasswordFieldType = this.confirmPasswordFieldType === 'password' ? 'text' : 'password'
    },
    switchVisibilityOld() {
      this.oldPasswordFieldType = this.oldPasswordFieldType === 'password' ? 'text' : 'password'
    },
    savePass() {
      this.errors = [];
      this.ui.saving = true

      let formData = new FormData();
      formData.append('oldPassword', this.formData.oldPassword.trim());
      formData.append('newPassword', this.formData.newPassword.trim());
      formData.append('repeatNewPassword', this.formData.confirmPassword.trim());

      this.$http.post(`/api/v1/staff/change-password/${this.$route.params.id}`, formData)
        .then((res) => {
          this.alertSuccess(res.data.message, 3700);
          this.$emit('close')
        })
        .catch((err) => {
          if (err.response.data.errors || err.response.status === 400) {
            this.errors = err.response.data.errors
            this.alertError(err.response.data.message)
          } else if (err.response.status === 502) {
            this.alertError(err.response.data.message)
          }else {
            this.alertError(err.response.statusText)
          }
        })
        .finally(() => {
          this.ui.saving = false
        })
    },
    getData() {
      this.ui.loading = true;
      setTimeout(() => {
        this.ui.loading = false;
      }, 500)
    }
  },
  created() {
    this.getData();
  }
}
</script>

<style lang="scss" scoped>
#changePasswordModal {
  .modal-header {
    position: relative;
  }

  .modal-body {
    padding: 0 30px;

    .card-info {
      &-title {
        opacity: 0.46;
        color: #000000;
        font-size: 11px;
        letter-spacing: 0;
        line-height: 12px;
      }
    }
  }
}

</style>
