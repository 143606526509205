<template>
  <div class="modal modal-small-center box-shadow-light border-small" id="userCardModal">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header border-0">
        <h3>
          Credit Card Info
        </h3>
        <img src="@/assets/icons/icon-remove.svg" class="cursor-pointer" alt="Close" @click="$emit('close')">
      </div>
      <div class="modal-body">
        <div class="text-center mt-3 mb-5" v-if="ui.loading === true">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div class="modal-body-container m-0" v-else>
          <div class="col-12">
            <div class="card-info">
            <span class="card-info-title">
              Card Number
            </span>
              <div class="d-flex">
                <input type="text" id="cardNumber" :value="creditCard.number" >
                <img src="@/assets/icons/icon-duplicate.svg" class="ms-2 cursor-pointer" alt="Duplicate" @click="copyToClipboard">
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6">
              <div class="card-info">
                <span class="card-info-title">
                  Valid thru
                </span>
                <div>
                  {{ creditCard.expiry }}
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="card-info">
                <span class="card-info-title">
                  Security Code
                </span>
                <div>
                  {{ creditCard.code }}
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6">
              <div class="card-info">
                <span class="card-info-title">
                  Name on Card
                </span>
                <div>
                  {{ creditCard.cardOwner }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 mt-3" v-if="isGranted('ROLE_CEO_ASSISTANT') || isGranted('ROLE_BOOKKEEPER')">
            <button class="btn btn-outline-dark" @click="ui.editCardModal = true">
              Edit Card
            </button>
          </div>
        </div>
      </div>
    </div>
    <transition name="component-fade" mode="out-in">
      <edit-card-modal v-if="ui.editCardModal === true"
                       :creditCard="creditCard"
                       :userId='parseInt(userId)'
                       @close="ui.editCardModal = false"
                       @success="getData"/>
    </transition>
  </div>
</template>

<script>
import EditCardModal from "./EditCardModal";

export default {
  name: 'UserCardModal',
  components: {
     EditCardModal
  },
  props: {
    userId: Number
  },
  data() {
    return {
      ui: {
        loading: false,
        editCardModal: false,
      },
      creditCard: {
        number: '',
        expiry: '',
        code: '',
        cardOwner: ''
      }
    }
  },
  methods: {
    getData() {
      this.ui.loading = true;
      this.$http.get(`/api/v1/staff/update-card/${this.userId}`)
        .then((res) => {
          this.creditCard = res.data
        })
        .catch((err) => {
          this.alertError(err.response.statusText)
        })
        .finally(() => {
          this.ui.loading = false;
        })
    },
    copyToClipboard() {
      try {
        document.getElementById('cardNumber').select();
        document.execCommand("copy");
      } catch (err) {
        alert('Copied failed!');
      }
    }
  },
  created() {
    this.getData()
  }
}
</script>

<style lang="scss">
#userCardModal {
  .modal-header {
    position: relative;
  }
  .modal-body {
    padding: 0 30px;
    #cardNumber {
      border: none;
      background: none;
      padding: 0;
      width: fit-content;
      height: fit-content;
      cursor: none;
      pointer-events: none;
      &:focus {
        outline: none;
      }
    }
    .card-info {
      &-title {
        opacity: 0.46;
        color: #000000;
        font-size: 11px;
        letter-spacing: 0;
        line-height: 12px;
      }
    }
  }
  button {
    max-width: 120px;
    width: 100%;
  }
}
</style>
