<template>
  <div class="modal">
    <div class="modal-bg" @click="$emit('close')"></div>

    <!--  Preload modal spinner  -->
    <modal-spinner v-if="ui.loading === true" />

    <div class="modal-block" v-else>
      <div class="modal-header">
        <h3> Upload Video</h3>
      </div>
      <div class="modal-body">
        <div class="modal-body-container">
          <div class="row mt-3">
            <div class="col-12">
                <label for="thumbnail">Video Thumbnail</label>
                <file-field :field-id="'fieldId'" :files="formData.files" :multiple="false" @change="onFileChange" />
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12" v-show="!videoUrl">
                <div @drop.prevent="handleDrop" @dragover.prevent @:dragleave.prevent>
                    <label for="thumbnail">Video</label>
                    <div class="scanned-doc position-relative text-center">
                        <img src="@/assets/icons/Illustration-Upload Border.svg" alt=""/>
                        <input type="file" ref="file" class="position-absolute" @change="submitFiles" accept="video/*" />
                        <div class="scanned-doc-text" v-if="file.length > 0">
                            <span>{{ file.length }}</span> file{{ file.length > 1 ? "(s)" : "" }}
                            selected
                        </div>
                        <div class="scanned-doc-text" v-else>
                            Drag File here or <span>Browse</span>
                        </div>
                        <button class="btn bg-primary" @click="$el.querySelector('input[type=file]').click()">
                            Select Video
                        </button>
                    </div>
                </div>
                <div v-if="ui.invalidFormat" class="full-width mt-2 text-danger">
                    Invalid File Format, Supported format is ({{ this.supportedFormat.join(', ') }})
                </div>
            </div>
            <div class="col-12" v-show="videoUrl">
                <div>
                    <div class="d-flex align-items-center justify-content-between">
                      <label for="thumbnail">Video</label>
                      <button class="btn btn-control" @click="editVideo">
                        <img alt="Edit" src="@/assets/icons/icon-edit-normal.svg">
                      </button>
                    </div>
                    <div class="profile-video">
                      <video controls 
                        :src="videoUrl" 
                        class="video-player" 
                        width="200" 
                      />
                    </div>
                </div>
            </div>
          </div>
          <div class="row mt-3">
              <div class="col-12">
                <ValidationErrorsList :error="ui.form.error" :errors="ui.form.errors" :isError="ui.form.isError"/>
              </div>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-start">
        <button class="btn bg-green" @click="saveData" :disabled="ui.saving">
          <span v-if="ui.saving" aria-hidden="true" class="spinner-border spinner-border-sm text-light" role="status"></span>
          <span v-else>Save</span>
        </button>
        <button class="btn btn-cancel ml-3" @click="$emit('close')">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ModalSpinner from "../../../components/modal/ModalSpinner";
import FileField from "../../../components/form/FileField";
import ValidationErrorsList from "../../../components/form/ValidationErrorsList";

export default {
  name: 'VideoModal',
  components: {ModalSpinner, FileField, ValidationErrorsList},
  props: {
    details: {type: Object},
    agentId: {type: Number, required: true}
  },
  data() {
    return {
      file: [],
      videoUrl: null,
      selectedvideo: {
        title: null,
        subtitle: null,
        thumbnail: null,
        video: null
      },
      ui: {
        saving: false,
        loading: false,
        invalidFormat: false,
        fileWidth: 0,
        isAdd: true,
        form: {
          isError: false,
          error: '',
          errors: [],
        }
      },
      formData: {
        files: [],
      },
      supportedFormat: ['mp4', 'webm', 'ogg']
    }
  },
  created() {
    if (Object.keys(this.details).length) {
      this.ui.isAdd = this.details.videoUrl ? false : true;
      this.selectedvideo.title = this.details.videoTitle;
      this.selectedvideo.subtitle = this.details.videoSubtitle;
      this.selectedvideo.thumbnail = this.details.videoThumbnail;
      this.selectedvideo.video = this.details.videoUrl;
      this.videoUrl = this.details.videoUrl;
    }
  },
  methods: {
    onFileChange(e) {
      let length = e.target.files.length
      for(let i = 0; i < length; i++) {
        this.formData.files.push(e.target.files[i])
      }
    },
    editVideo() {
      this.file = [];
      this.$refs.file.value = '';
      this.videoUrl = null;
    },
    handleDrop(e) {
      this.$refs.file.files = e.dataTransfer.files;
      this.submitFiles();
    },
    submitFiles() {
      this.file = [...this.$refs.file.files];
      this.ui.invalidFormat = false;

      if (this.file.length > 0) {
        const fileExt = this.file[0].name.split('.').pop().toLowerCase();
        if (!this.supportedFormat.includes(fileExt)) {
          this.file = [];
          this.videoUrl = null;
          this.ui.invalidFormat = true;
          return;
        }

        this.ui.fileWidth = (this.file[0].size / (1024 * 1024)).toFixed(2);
        this.videoUrl = URL.createObjectURL(this.file[0]);
      }
    },
    saveData() {
      this.ui.form.isError = false;
      this.ui.invalidFormat = false;
      this.ui.saving = true;
      let formData = new FormData();
      formData.append('isAdd', this.ui.isAdd);
      formData.append('title', this.selectedvideo.title);
      formData.append('subtitle', this.selectedvideo.subtitle);
      formData.append('video', this.file.length ? this.file[0] : '');
      formData.append('thumbnail', this.formData.files.length ? this.formData.files[0] : '');
      this.$http.post(`/api/v1/staff/video/edit/${this.agentId}`, formData).then((res) => {
        this.alertSuccess(res.data.message);
        this.$emit('success');
      }).catch((err) => {
        this.alertError(err.response.data ? err.response.data.message : err.response.statusText); 
        let validation = this.getErrorsFromResponse(err.response)
        this.ui.form.isError = true
        this.ui.form.error = validation.error
        this.ui.form.errors = validation.errors

      }).finally(() => this.ui.saving = false)
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-block {
  .modal-title {
    color: rgba(0,0,0,.46);
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    padding-bottom: 14px;
  }
  .modal-footer {
    button {
      &.bg-green {
        max-width: 110px!important;
        width: 100%;
      }
    }
  }
}

.scanned-doc {
  padding: 72px 0;
  &-text {
    color: #000000;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 14px;
    margin: 24px 0 20px 0;
  }

  .btn {
    &.bg-primary {
      height: 36px;
      width: 124px;
      border-radius: 8px;
      background-color: #0057ed;
      color: #ffffff;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px;
    }
  }

  input {
    height: 100%;
    display: flex;
    top: 0;
  }
}

.profile-video {
  width: 100%;
  height: fit-content;
  border: 1px solid #dadada;
  border-radius: 8px;
  background-color: #fff;
  padding: 10px;
  video {
    width: 100%;
  }
}
</style>
