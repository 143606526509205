<template>
  <div class="modal" id="EditPersonalDetailsModal">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header">
        <h3>
          Edit Personal Details
        </h3>
      </div>
      <div class="modal-body" id="modal-body-scroll">
        <div class="text-center mt-4" v-if="ui.loading === true">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div v-else>
          <div class="modal-body-container">
            
            <!--Personal details-->
            <fieldset class="border p-4">
              <legend  class="float-none w-auto">Personal Info</legend>
              <div class="row mt-3">
                <div class="col-6">
                  <div class="form-group full-width">
                    <label for="fieldEmail">
                      Email
                    </label>
                    <input type="email" class="form-control" id="fieldEmail"
                      :class="{'input-filled': formData.personalEmail && formData.personalEmail.length}"
                      v-model="formData.personalEmail">
                  </div>
                  <span class="error-msg" v-for="(error, index) in getError('personalEmail', errors)" :key="index">
                    {{ error }}
                  </span>
                </div>
                <div class="col-6">
                  <div class="form-group full-width">
                    <label for="fieldPhone">
                      Phone Number
                    </label>
                    <input type="text" class="form-control" id="fieldPhone"
                          :class="{'input-filled': formData.personalPhone && formData.personalPhone.length}"
                          v-model="formData.personalPhone"
                          v-mask="'(###) ###-####'">
                  </div>
                  <span class="error-msg" v-for="(error, index) in getError('personalPhone', errors)" :key="index">
                    {{ error }}
                  </span>
                </div>
              </div>
              
              <div class="row mt-3">
                <div class="col-6">
                  <div class="form-group full-width">
                    <label for="fieldDob">Date of birth</label>
                    <input type="date" class="form-control" id="fieldDob" v-model="formData.dob"
                          :class="{'input-filled': formData.dob && formData.dob.length}">
                  </div>
                  <span class="error-msg" v-for="(error, index) in getError('dob', errors)" :key="index">
                    {{ error }}
                  </span>
                </div>
                <div class="col-6">
                  <div class="form-group full-width">
                    <label for="fieldSecurity">Social Security number (SSN)</label>
                    <input type="text" class="form-control" id="fieldSecurity" v-model="formData.securityNumber"
                          :class="{'input-filled': formData.securityNumber && formData.securityNumber.length}">
                  </div>
                  <span class="error-msg" v-for="(error, index) in getError('securityNumber', errors)" :key="index">
                    {{ error }}
                  </span>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-6">
                  <div class="form-group full-width">
                    <label for="fieldState">
                      Gender
                    </label>
                    <multiselect v-model="formData.gender"
                                  id="fieldState"
                                  class="full-width profile-visibility w-md-100"
                                  :options="genders"
                                  :multiple="false"
                                  :selectedLabel="'✓'"
                                  :searchable="false"
                                  :selectLabel="''"
                                  :deselectLabel="''"
                                  :close-on-select="true"
                                  :allow-empty="false"
                                  placeholder="Select Gender">
                    </multiselect>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12">
                  <div class="form-group full-width">
                    <label for="fieldAddress">
                      Address
                    </label>
                    <input type="text" class="form-control" id="fieldAddress"
                          :class="{'input-filled': formData.address && formData.address.length}"
                          v-model="formData.address">
                  </div>
                  <span class="error-msg" v-for="(error, index) in getError('address', errors)" :key="index">
                    {{ error }}
                  </span>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-6">
                  <div class="form-group full-width">
                    <label for="fieldCity">
                      City
                    </label>
                    <input type="text" class="form-control" id="fieldCity"
                          v-model="formData.city"
                          :class="{'input-filled': formData.city && formData.city.length}">
                  </div>
                </div>
                <div class="col-3">
                  <div class="form-group full-width">
                    <label for="fieldState">
                      State
                    </label>
                    <multiselect v-model="formData.state"
                                id="fieldState"
                                track-by="value" label="label"
                                class="full-width profile-visibility w-md-100"
                                :options="states"
                                :multiple="false"
                                :selectedLabel="'✓'"
                                :searchable="false"
                                :selectLabel="''"
                                :deselectLabel="''"
                                :close-on-select="true"
                                :allow-empty="false"
                                placeholder="Select State">
                    </multiselect>
                  </div>
                </div>
                <div class="col-3">
                  <div class="form-group full-width">
                    <label for="fieldZip">
                      Zip
                    </label>
                    <input type="text" class="form-control" id="fieldZip"
                          v-model="formData.zip"
                          :class="{'input-filled': formData.zip && formData.zip.length}">
                  </div>
                </div>
              </div>
            </fieldset>

            <!--Emergency details-->
            <fieldset class="border p-4 mt-4">
              <legend  class="float-none w-auto">Emergency Contact Info</legend>
              <div class="row mt-3">
                <div class="col-6">
                  <div class="form-group full-width">
                    <label for="fieldEmergencyName">
                      Name
                    </label>
                    <input type="text" class="form-control" id="fieldEmergencyName"
                          :class="{'input-filled': formData.emergencyName && formData.emergencyName.length}"
                          v-model="formData.emergencyName"
                          >
                  </div>
                  <span class="error-msg" v-for="(error, index) in getError('emergencyName', errors)" :key="index">
                    {{ error }}
                  </span>
                </div>
                <div class="col-6">
                  <div class="form-group full-width">
                    <label for="fieldEmergencyPhone">
                      Phone Number
                    </label>
                    <input type="text" class="form-control" id="fieldEmergencyPhone"
                          :class="{'input-filled': formData.emergencyPhone && formData.emergencyPhone.length}"
                          v-model="formData.emergencyPhone"
                          v-mask="'(###) ###-####'">
                  </div>
                  <span class="error-msg" v-for="(error, index) in getError('emergencyPhone', errors)" :key="index">
                    {{ error }}
                  </span>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12">
                  <div class="form-group full-width">
                    <label for="fieldEmerAddress">
                      Address
                    </label>
                    <input type="text" class="form-control" id="fieldEmerAddress"
                          :class="{'input-filled': formData.emergencyAddress && formData.emergencyAddress.length}"
                          v-model="formData.emergencyAddress"
                          >
                  </div>
                  <span class="error-msg" v-for="(error, index) in getError('address', errors)" :key="index">
                    {{ error }}
                  </span>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-6">
                  <div class="form-group full-width">
                    <label for="fieldEmergencyCity">
                      City
                    </label>
                    <input type="text" class="form-control" id="fieldEmergencyCity"
                          :class="{'input-filled': formData.emergencyCity && formData.emergencyCity.length}"
                          v-model="formData.emergencyCity"
                          >
                  </div>
                  <span class="error-msg" v-for="(error, index) in getError('city', errors)" :key="index">
                    {{ error }}
                  </span>
                </div>
                <div class="col-3">
                  <div class="form-group full-width">
                    <label for="fieldState">
                      State
                    </label>
                    <multiselect v-model="formData.emergencyState"
                            id="fieldState"
                            track-by="value" label="label"
                            class="full-width profile-visibility w-md-100"
                            :options="states"
                            :multiple="false"
                            :selectedLabel="'✓'"
                            :searchable="false"
                            :selectLabel="''"
                            :deselectLabel="''"
                            :close-on-select="true"
                            :allow-empty="false"
                            placeholder="Select State">
                    </multiselect>
                  </div>
                  <span class="error-msg" v-for="(error, index) in getError('state', errors)" :key="index">
                    {{ error }}
                  </span>
                </div>
                <div class="col-3">
                  <div class="form-group full-width">
                    <label for="fieldEmergencyZip">
                      Zip
                    </label>
                    <input type="text" class="form-control" id="fieldEmergencyZip"
                          :class="{'input-filled': formData.emergencyZip && formData.emergencyZip.length}"
                          v-model="formData.emergencyZip"
                          >
                  </div>
                  <span class="error-msg" v-for="(error, index) in getError('zip', errors)" :key="index">
                    {{ error }}
                  </span>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-start" v-if="ui.loading === false">
        <button type="submit"
                @click="saveUser"
                class="btn bg-primary submit">
          <span v-if="ui.saving"
                class="spinner-border spinner-border-sm"
                role="status" aria-hidden="true">
          </span>
          <span v-if="!ui.saving">Save</span>
        </button>
        <button type="reset" class="btn btn-cancel ms-4" @click="$emit('close')">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import PerfectScrollbar from 'perfect-scrollbar';

export default {
  name: 'EditPersonalDetailsModal',
  components: { Multiselect },
  props: {
    personal: {type: Object},
    dob: {type: String}
  },
  data() {
    return {
      ui: {
        saving: false,
        loading: false,
        ps: null
      },
      formData: {
        personalEmail: '',
        personalPhone: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        dob: '',
        securityNumber: '',
        gender: '',
        emergencyName: '',
        emergencyPhone: '',
        emergencyAddress: '',
        emergencyCity: '',
        emergencyState: '',
        emergencyZip: '',    
      },
      states: [],
      errors: [],
      genders: [
        'Male',
        'Female'
      ]
    }
  },
  methods: {
    getStates() {
      this.ui.loading = true
      this.$http.get(`/api/v1/staff/state`)
        .then((res) => {
          this.states = res.data.states
        })
        .finally(() => {
          this.ui.loading = false;
        })
    },
    saveUser() {
      this.ui.saving = true;
      this.errors = []

      let formData = new FormData()
      formData.append('personalEmail', this.formData.personalEmail)
      formData.append('personalPhone', this.formData.personalPhone)
      formData.append('address', this.formData.address)
      formData.append('city', this.formData.city)
      formData.append('state', this.formData.state ? this.formData.state.value : '')
      formData.append('zip', this.formData.zip)
      formData.append('dob', this.formData.dob)
      formData.append('securityNumber', this.formData.securityNumber)
      formData.append('gender', this.formData.gender),
      formData.append('emergencyName', this.formData.emergencyName)
      formData.append('emergencyPhone', this.formData.emergencyPhone)
      formData.append('emergencyAddress', this.formData.emergencyAddress)
      formData.append('emergencyCity', this.formData.emergencyCity)
      formData.append('emergencyState', this.formData.emergencyState ? this.formData.emergencyState.value : '')
      formData.append('emergencyZip', this.formData.emergencyZip)

      this.$http.post(`/api/v1/staff/edit-personal-details/${this.$route.params.id}`, formData)
        .then((res) => {
          if (res.data.status === 'success') {
            this.alertSuccess(res.data.message)
            this.$emit('success')
            this.$emit('close')
            this.$root.$emit('updateUser')
          } else {
            this.alertError(res.data.message)
          }
        })
        .catch((err) => {
            if (err.response.data.errors || err.response.status === 400) {
              this.errors = err.response.data.errors
              this.alertError(err.response.data.message)
            } else if (err.response.status === 502) {
              this.alertError(err.response.data.message)
            }else {
              this.alertError(err.response.statusText)
            }
        })
        .finally(() => {
          this.ui.saving = false
        })
    }
  },
  created() {
    this.getStates()
    this.formData.personalEmail = this.personal.personalEmail
    this.formData.personalPhone = this.personal.personalPhone
    this.formData.address = this.personal.address
    this.formData.city = this.personal.city
    this.formData.state = {
      label: this.personal.state,
      value: this.personal.state,
    }
    this.formData.zip = this.personal.zip
    this.formData.dob = this.dob
    this.formData.gender = this.personal.gender;
    this.formData.securityNumber = this.personal.securityNumber
    this.formData.emergencyName = this.personal.emergencyContactName
    this.formData.emergencyPhone = this.personal.emergencyContactPhone
    this.formData.emergencyAddress = this.personal.emergencyContactAddress
    this.formData.emergencyCity = this.personal.emergencyContactCity
    this.formData.emergencyState = {
      label: this.personal.emergencyContactState,
      value: this.personal.emergencyContactState
    }
    this.formData.emergencyZip = this.personal.emergencyContactZip
  },
  mounted() {
    const scrollbar = document.getElementById('modal-body-scroll');
    this.ui.ps = new PerfectScrollbar(scrollbar);
  }
}
</script>

<style lang="scss" scoped>
.modal-body-container::v-deep .multiselect {

  &.multiselect--active {
    .multiselect__tags {
      transition: all .3s;
      border: 1px solid rgba(38,111,229,0.5);
      box-shadow: 0 0 0 4px rgba(38,111,229,0.1);
    }
  }
  &__tags {
    padding: 8px 40px 0 8px;
    transition: all .3s;
    &:hover {
      border: 1px solid rgba(38,111,229,0.2);
      box-shadow: 0 0 0 4px rgba(38,111,229,0.1);
    }
  }
  &__select {
    &:before {
      top: 15%;
      position: relative;
      right: 0;
      color: #999;
      border: solid rgba(0, 0, 0, 0.46);
      margin-top: 4px;
      border-width: 0px 1px 1px 0;
      display: inline-block;
      padding: 3px;
      content: "";
      transform: rotate(44deg);
    }
  }
  &__single {
    color: rgba(0, 0, 0, 0.46);
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }
  }
  &__option {
    padding: 10px 12px;
    min-height: 36px;
    transition: all .3s;
    line-height: 20px;
    font-size: 14px;
    color: #000;
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }
    &--highlight {
      background: #f3f3f3;
      line-height: 20px;
      font-size: 14px;
      color: #000;
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }
    }
  }
}

#modal-body-scroll {
  position: relative;
  height: calc(100% - 100px);
}
#EditPersonalDetailsModal {
  .form-switch {
    margin: 0;
    label {
      opacity: 0.46;
      color: rgba(0,0,0,1);
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
      &.active {
        color: rgba(0,0,0,1);
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24px;
        opacity: 1;
      }
    }
  }
  .input-icon {
    img {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
}
.ck.ck-editor__main>.ck-editor__editable{
  min-height: 200px!important;
  border-bottom-left-radius: 8px!important;
  border-bottom-right-radius: 8px!important;
}
.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
</style>
