<template>
  <div class="modal">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header">
        <h3>Metrics for {{ agent.name }}</h3>
      </div>
      <div class="modal-body">
        <div class="text-center mt-4" v-if="ui.loading === true">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div v-else>
          <div class="modal-body-container border-0">
            <div class="form-group">
              <label for="fieldBankName">Meta Pixel ID</label>
              <input type="text" class="form-control" id="fieldBankName"
                     :class="{'input-filled': formData.metaPixelId.length}"
                     v-model="formData.metaPixelId">
            </div>
<!--            <div class="form-group mt-4">-->
<!--              <label for="fieldAccountName">Google Analytics</label>-->
<!--              <input type="text" class="form-control" id="fieldAccountName"-->
<!--                     :class="{'input-filled': formData.googleAnalytics.length}"-->
<!--                     v-model="formData.googleAnalytics">-->
<!--            </div>-->
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-start" v-if="ui.loading === false">
        <button class="btn bg-green" @click="savePayment" :disabled="ui.saving || formData.metaPixelId.length === 0">
          <span v-if="ui.saving"
                class="spinner-border spinner-border-sm text-light"
                role="status" aria-hidden="true">
          </span>
          <span class="text-light" v-else>
            Save
          </span>
        </button>
        <button class="btn btn-cancel ms-4" @click="$emit('close')">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserMetricsModal',
  props: {
    agent: Object,
    metaPixelId: String
  },
  data() {
    return {
      formData: {
        metaPixelId: '',
        googleAnalytics: '',
      },
      ui: {
        loading: false,
        saving: false
      }
    }
  },
  methods: {
    getData() {
      this.ui.loading = true;
      this.formData.metaPixelId = this.metaPixelId
      // this.formData.googleAnalytics = this.metrics.accountName
      setTimeout(() => {
        this.ui.loading = false;
      }, 500)
    },
    savePayment() {
      this.ui.saving = true;
      let formData = new FormData()
      formData.append('metaPixelId', this.formData.metaPixelId)
      // formData.append('googleAnalytics', this.formData.accName)

      this.$http.post(`/api/v1/staff/update-metrics/${this.agent.id}`, formData)
        .then((res) => {
          this.alertSuccess(res.data.message)
          this.$emit('success')
          this.$emit('close')
        })
        .catch((err) => {
          this.alertError(err.response.data ? err.response.data.message : err.response.statusText)
        })
        .finally(() => {
          this.ui.saving = false;
        })
    }
  },
  created() {
    this.getData();
  }
}
</script>

<style lang="scss" scoped>
.user-name {
  color: #000000;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
  margin-left: 18px;
}
.user-position {
  opacity: 0.46;
  color: #000000;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
  margin-left: 12px;
}
.modal-footer {
  button {
    &.bg-green {
      max-width: 110px;
      width: 100%;
    }
  }
}
</style>
