<template>
  <div class="modal">
    <div class="modal-bg" @click="$emit('close')"></div>

    <!--  Preload modal spinner  -->
    <modal-spinner v-if="ui.loading === true" />

    <div class="modal-block" v-else>
      <div class="modal-header">
        <h3> {{ selectedReview.id  ? 'Edit' : 'Add' }} Review</h3>
      </div>
      <div class="modal-body">
        <div class="modal-title"></div>
        <div class="modal-body-container">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label for="name">Client Name</label>
                <input type="text" class="form-control" id="name" placeholder="John Smith" autocomplete="off"
                       v-model="selectedReview.clientName">
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12">
              <div class="form-group">
                <label for="source">Source</label>
                <input type="text" class="form-control" id="source" placeholder="Zillow"
                         v-model="selectedReview.source">
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12">
              <div class="form-group">
                <label for="text">Review Text</label>
                  <ckeditor
                      :editor="editor"
                      v-model="selectedReview.reviewText"
                      id="text"
                      :config="editorConfig"
                    >
                    </ckeditor>
                  <!-- <textarea class="form-control" id="text" placeholder="Review Text"
                         v-model="" rows="10"></textarea> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-start">
        <button class="btn bg-green" @click="saveData" :disabled="ui.saving">
          <span v-if="ui.saving" aria-hidden="true" class="spinner-border spinner-border-sm text-light" role="status"></span>
          <span v-else>Save</span>
        </button>
        <button class="btn btn-cancel ml-3" @click="$emit('close')">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ModalSpinner from "../../../components/modal/ModalSpinner";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export default {
  name: 'ReviewModal',
  components: {ModalSpinner},
  props: {
    review: {type: Object}
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {},
      selectedReview: {
        id: null,
        clientName: null,
        source: null,
        reviewText: null
      },
      ui: {
        loading: false,
        saving: false
      }
    }
  },
  created() {
    if (Object.keys(this.review).length) {
      this.selectedReview.id = this.review.id;
      this.selectedReview.clientName = this.review.clientName;
      this.selectedReview.source = this.review.source;
      this.selectedReview.reviewText = this.review.reviewText;
    }
  },
  methods: {
    saveData() {
      this.ui.saving = true;
      let url = this.selectedReview.id
        ? `/api/v1/reviews/${this.selectedReview.id}/edit`
        : `/api/v1/reviews/${this.$route.params.id}/new`;

      let formData = new FormData();
      formData.append('clientName', this.selectedReview.clientName);
      formData.append('source', this.selectedReview.source);
      formData.append('reviewText', this.selectedReview.reviewText);
      this.$http.post(url, formData).then((res) => {
        this.$emit('success', res.data.data)
      }).catch((err) => {
        this.alertError(err.response.data ? err.response.data.message : err.response.statusText); 
      }).finally(() => this.ui.saving = false)
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-block {
  .modal-title {
    color: rgba(0,0,0,.46);
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    padding-bottom: 14px;
  }
  .modal-footer {
    button {
      &.bg-green {
        max-width: 110px!important;
        width: 100%;
      }
    }
  }
  .modal-body-container {
    .form-group {
      max-width: 400px;
    }
  }
}
.ck-editor .ck-editor__main .ck-content {
  min-height: 200px !important;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
</style>
