<template>
  <div id="userDetails" class="page-content-grey">
    <div class="page-header d-flex justify-content-between align-items-center">
      <div>
        <router-link v-if="isGranted('ROLE_CEO') || permissions['ceoAssistant']" :class="'text-decoration-none'"
                     :to="{ name: 'users'}">
          <div class="d-flex">
            <img alt="Back to Users" class="me-2" src="@/assets/icons/bold-icon previous.svg">
            <h3 class="page-header-title m-0">User Profile</h3>
          </div>
        </router-link>
        <div v-else>
          <div class="d-flex">
            <h3 class="page-header-title m-0">
              User Profile
            </h3>
          </div>
        </div>
      </div>
    </div>
    <div class="page-content">
      <div v-if="ui.loading === true" class="text-center mt-3 mb-4">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div v-else>
        <div class="col-12 agent">
          <div class="row w-100 m-auto">
            <div class="col-md-5 col-lg-3 col-xl-2 p-0">
              <div class="position-relative">
                <v-avatar :size="158" :src="agentPhoto(agent.profileImage)" rounded/>
                <button class="btn btn-control edit-agent"
                        v-if="!permissions['feeSplit'] && !isGranted('ROLE_ANALYST') || isGranted('ROLE_CEO_ASSISTANT')"
                        @click="ui.showUpdateAvatar = true">
                  <img alt="Edit" src="@/assets/icons/icon-edit-normal.svg">
                </button>
                <transition mode="out-in" name="component-fade">
                  <my-upload ref="avatar" v-model="ui.showUpdateAvatar"
                             :height="400"
                             :noSquare="true"
                             :url="backendUrl(`/api/v1/staff/change-profile/${agent.id}`)"
                             :width="400"
                             field="avatar"
                             img-format="png"
                             lang-type="en"
                             @crop-upload-success="cropUploadSuccess">
                  </my-upload>
                </transition>
              </div>
            </div>
            <div class="col-md-7 col-lg-9 col-xl-10 agent-info">
              <div class="col-12">
                <div class="row">
                  <div class="col-6">
                    <div class="agent-name">
                      {{ agent.name }}
                      <div :class="[ agent.isOnline ? 'user-active' : 'user-unactive' ]"></div>
                    </div>
                  </div>
                  <div v-if="isGranted('ROLE_CEO') && $store.getters.getUserId != agent.id" class="col-6 text-end">
                    <router-link v-tippy="{arrow: true, placement: 'top-center'}"
                                 :to="{ name: 'auth.login-as', params: {currentUserToken: agent.currentUserToken, id: agent.id}, query: {
                                   token: $store.getters.getToken
                                 }}"
                                 class="router-link btn btn-outline-light login-as text-center"
                                 content="Consider to use Incognito Window">
                      <img alt="Login as" src="@/assets/icons/icon-profile.svg">
                      Login as {{ agent.name }}
                    </router-link>
                  </div>
                </div>
                <div class="agent-position">
                  {{ agent.position }}
                </div>
                <div class="row mt-4 agent-actions">
                  <div class="col-12 col-md-7 col-lg-10 d-flex">
                    <button class="btn btn-control" @click="telCall">
                      <img alt="Call line" src="@/assets/icons/bold-icon Call line.svg">
                    </button>
                    <button class="btn btn-control" @click="mailtoCall">
                      <img alt="Email line" src="@/assets/icons/bold-icon Email line.svg">
                    </button>
                    <button v-if="agent.isGranted['edit'] && $store.getters.getUserId !== agent.id && !isGranted('ROLE_ANALYST')" class="btn btn-outline-light"
                            @click="ui.modals.cardModal = true">
                      <img alt="Credit card" src="@/assets/icons/icon-credit-card.svg">
                      <span>
                        Credit Card
                      </span>
                    </button>
                    <button v-if="permissions['resetPassword'] && $store.getters.getUserId !== agent.id && !isGranted('ROLE_ANALYST')"
                            :disabled="ui.resetPassLoading"
                            class="btn btn-outline-light btn-change-password"
                            @click="resetPassword">
                        <span v-if="ui.resetPassLoading"
                              aria-hidden="true"
                              class="spinner-border spinner-border-sm"
                              role="status"></span>
                      <span v-else>
                          <img alt="Reset password" src="@/assets/icons/icon change password.svg">
                          Reset Password
                        </span>
                    </button>
                    <button v-else-if="agent.isGranted['edit'] && !isGranted('ROLE_ANALYST')"
                            class="btn btn-outline-light btn-change-password"
                            @click="ui.modals.changePasswordModal = true">
                      <img alt="Change password" src="@/assets/icons/icon change password.svg">
                      <span>
                        Change Password
                      </span>
                    </button>
                    <button v-if="isGranted('ROLE_CEO') || isGranted('ROLE_CEO_ASSISTANT')"
                            :disabled="ui.reset2faLoading"
                            class="btn btn-outline-light btn-2fa"
                            @click="reset2fa">
                        <span v-if="ui.reset2faLoading"
                              aria-hidden="true"
                              class="spinner-border spinner-border-sm"
                              role="status"></span>
                        <span v-else>
                          <img alt="Reset password" src="@/assets/icons/2fa.svg">
                          Reset 2FA
                        </span>
                    </button>
                    <button v-if="agent.isBlockedByAttempts && (isGranted('ROLE_CEO') || isGranted('ROLE_CEO_ASSISTANT'))"
                            :disabled="ui.reset2faLoading"
                            class="btn btn-outline-light btn-change-password"
                            @click="unlockAccount">
                        <span v-if="ui.unlockAccount"
                              aria-hidden="true"
                              class="spinner-border spinner-border-sm"
                              role="status"></span>
                        <span v-else>
                          <img alt="Reset password" src="@/assets/icons/2fa.svg">
                          Unlock Account
                        </span>
                    </button>
                  </div>
                  <div class="col-12 col-md-12 col-lg-2 d-flex justify-content-start justify-content-lg-end mt-3 mt-lg-0" v-if="permissions['ceoAssistant']">
                    <div class="position-relative">
                      <img alt="Ethernet" class="position-absolute select-icon"
                           src="@/assets/icons/icon-website green.svg">
                      <multiselect id="fieldFilter"
                                   v-model="agent.visibility"
                                   :allow-empty="false" :close-on-select="true"
                                   :deselectLabel="''"
                                   :multiple="false"
                                   :options="profileVisibilities"
                                   :searchable="false"
                                   :selectLabel="''"
                                   :selectedLabel="'✓'"
                                   class="full-width profile-visibility w-md-100"
                                   label="label"
                                   track-by="value"
                                   @select="changeVisiblity">
                      </multiselect>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="page-tabs d-flex justify-content-between">
          <ul class="tab-list">
            <li :class="{ active: activeTab === 'details' }" @click="toggleTab('details')">Details</li>
            <li :class="{ active: activeTab === 'personal' }" @click="toggleTab('personal')"> Personal </li>
            <li v-if="permissions['ceoAssistant']" :class="{ active: activeTab === 'account' }" @click="toggleTab('account')">Account</li>
            <li v-if="permissions['ceoAssistant']" :class="{ active: activeTab === 'role' }" @click="toggleTab('role')">
              Role & Permissions
            </li>
            <li v-if="permissions['feeCrud'] || permissions['feeSplit']" :class="{ active: activeTab === 'fees' }" @click="toggleTab('fees')">
              Fees
            </li>
            <li v-if="details.role === 'Processor' && permissions['ceoAssistant']"
                :class="{ active: activeTab === 'limits' }" @click="toggleTab('limits')">Limits
            </li>
            <li v-if="(!permissions['feeSplit'] && showOwnTab) || permissions['ceoAssistant']" :class="{ active: activeTab === 'payment' }" @click="toggleTab('payment')">Payment</li>
            <li v-if="(!permissions['feeSplit'] && showOwnTab) || permissions['ceoAssistant']" :class="{ active: activeTab === 'w9' }" @click="toggleTab('w9')">W9</li>
            <li v-if="!permissions['feeSplit'] || permissions['ceoAssistant']" :class="{ active: activeTab === 'documents' }" @click="toggleTab('documents')">Documents</li>
            <li v-if="isGranted('ROLE_CEO_ASSISTANT') || isGranted('ROLE_ANALYST')" :class="{ active: activeTab === 'notes' }" @click="toggleTab('notes')">Notes</li>
            <li v-if="showCompSetting" :class="{ active: activeTab === 'comp' }" @click="toggleTab('comp')">
              Comp Settings
            </li>
            <li :class="{ active: activeTab === 'review' }" @click="toggleTab('review')">
              Reviews & Video
            </li>
          </ul>
        </div>
        <div class="tabs">
          <div v-if="activeTab === 'details'" class="tab">
            <div class="tab-header d-flex align-items-center">
              Details
              <button v-if="agent.isGranted['edit'] || isGranted('ROLE_ANALYST')" class="btn btn-control ms-2"
                      @click="ui.modals.editUserModal = true">
                <img alt="Edit Details" src="@/assets/icons/icon-edit-normal.svg">
              </button>
            </div>
            <div class="table">
              <table class="table about-table">
                <tbody>
                <tr>
                  <td><span>Full Name</span></td>
                  <td>{{ details.fullName }}</td>
                </tr>
                <tr>
                  <td><span>Title</span></td>
                  <td>{{ details.title }}</td>
                </tr>
                <tr>
                  <td><span>Primary Role</span></td>
                  <td>{{ details.role }}</td>
                </tr>
                <tr>
                  <td><span>Email Address</span></td>
                  <td>{{ details.email }}</td>
                </tr>
                <tr>
                  <td><span>Phone</span></td>
                  <td>{{ details.phone | toUSANumber }}</td>
                </tr>
                <tr>
                  <td><span>Fax</span></td>
                  <td>{{ details.fax }}</td>
                </tr>
                <tr>
                  <td><span>NMLS</span></td>
                  <td>{{ details.nmls }}</td>
                </tr>
                <tr>
                  <td><span>States</span></td>
                  <td>{{ details.states }}</td>
                </tr>
                <tr>
                  <td><span>Hired By</span></td>
                  <td>{{ details.hiredBy }}</td>
                </tr>
                <tr>
                  <td><span>Branch</span></td>
                  <td>{{ details.branch }}</td>
                </tr>
                <tr>
                  <td><span>Added to Vision</span></td>
                  <td>{{ formatDate(details.addedBy) }}</td>
                </tr>
                <tr>
                  <td><span>About</span></td>
                  <td v-html="details.about"></td>
                </tr>
                <tr>
                  <td><span>Apply URL</span></td>
                  <td>
                    <a v-if="details.applyURL" :href="details.applyURL" class="btn-link"
                       target="_blank">{{ details.applyURL }}</a>
                  </td>
                </tr>
                <tr>
                  <td><span>Rate Quote URL</span></td>
                  <td>
                    <a v-if="details.rateQuoteURL" :href="details.rateQuoteURL" class="btn-link"
                       target="_blank">{{ details.rateQuoteURL }}</a>
                  </td>
                </tr>
                <tr>
                  <td><span>Calendy URL</span></td>
                  <td>
                    <a v-if="details.calendyURL" :href="details.calendyURL" class="btn-link"
                       target="_blank">{{ details.calendyURL }}</a>
                  </td>
                </tr>
                <tr>
                  <td><span>Documents URL</span></td>
                  <td>
                    <a v-if="details.documentsURL" :href="details.documentsURL" class="btn-link"
                       target="_blank">{{ details.documentsURL }}</a>
                  </td>
                </tr>
                <tr>
                  <td><span>Rate quote funnel URL</span></td>
                  <td>
                    <a v-if="details.rateQuoteFunnelUrl" :href="details.rateQuoteFunnelUrl" class="btn-link"
                       target="_blank">{{ details.rateQuoteFunnelUrl }}</a>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-if="activeTab === 'personal'" class="tab">
            <div class="tab-header d-flex align-items-center">
              Personal
              <button v-if="agent.isGranted['edit'] || isGranted('ROLE_ANALYST')" class="btn btn-control ms-2"
                      @click="ui.modals.editPersonalModal = true">
                <img alt="Edit Personal Details" src="@/assets/icons/icon-edit-normal.svg">
              </button>
            </div>
            <fieldset class="border p-4 mt-4">
              <legend class="float-none w-auto">Personal Info</legend>
              <table class="table table-personal my-0">
                <tbody>
                  <tr>
                    <td><span>Phone Number</span></td>
                    <td>{{ personals.personalPhone | toUSANumber }}</td>
                  </tr>
                  <tr>
                    <td><span>Email Address</span></td>
                    <td>{{ personals.personalEmail | formatText }}</td>
                  </tr>
                  <tr>
                    <td><span>Address</span></td>
                    <td>{{ personals.address | formatText }}</td>
                  </tr>
                  <tr>
                    <td><span>City</span></td>
                    <td>{{ personals.city | formatText }}</td>
                  </tr>
                  <tr>
                    <td><span>State</span></td>
                    <td>{{ personals.state | formatText }}</td>
                  </tr>
                  <tr>
                    <td><span>Zip</span></td>
                    <td>{{ personals.zip | formatText }}</td>
                  </tr>
                  <tr>
                    <td><span>Date Of Birth</span></td>
                    <td>{{ agent.dob ? formatDate(agent.dob) : 'N/A' }}</td>
                  </tr>
                  <tr>
                    <td><span>Social Security Number (SSN)</span></td>
                    <td>{{ personals.securityNumber | secureVisible }}</td>
                  </tr>
                  <tr>
                    <td><span>Gender</span></td>
                    <td>{{ personals.gender | formatText }}</td>
                  </tr>
                </tbody>
              </table>
            </fieldset>

            <fieldset class="border p-4 mt-4">
              <legend  class="float-none w-auto">Emergency Contact Info</legend>
              <table class="table table-personal my-0">
                <tbody>
                  <tr>
                    <td><span>Name</span></td>
                    <td>{{ personals.emergencyContactName | formatText }}</td>
                  </tr>
                  <tr>
                    <td><span>Phone Number</span></td>
                    <td>{{ personals.emergencyContactPhone | toUSANumber }}</td>
                  </tr>
                  <tr>
                    <td><span>Address</span></td>
                    <td>{{ personals.emergencyContactAddress | formatText }}</td>
                  </tr>
                  <tr>
                    <td><span>City</span></td>
                    <td>{{ personals.emergencyContactCity | formatText }}</td>
                  </tr>
                  <tr>
                    <td><span>State</span></td>
                    <td>{{ personals.emergencyContactState | formatText }}</td>
                  </tr>
                  <tr>
                    <td><span>Zip</span></td>
                    <td>{{ personals.emergencyContactZip | formatText }}</td>
                  </tr>
                </tbody>
              </table>
            </fieldset>
          </div>
          <div v-else-if="activeTab === 'account' && isGranted('ROLE_CEO_ASSISTANT')" class="tab">
            <div class="tab-header d-flex align-items-center" v-if="isGranted('ROLE_CEO')">
              Account Configuration
            </div>
            <div class="table">
              <table class="table table-account">
                <tbody>
                  <tr>
                    <td>
                      <div>Show in Referral</div>
                      <span>Will appear for each licensed state as LO of Record</span>
                    </td>
                    <td>
                      <input class="form-check-input" v-model="account.availableForAllStates"
                            @change="toggleStateVisibility"
                            type="checkbox" id="availableForAllStates">
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>Borrower Birthday Wishes</div>
                      <span>Send birthday wishes email to borrower</span>
                    </td>
                    <td>
                      <input class="form-check-input" v-model="account.borrowerBirthdayWishes"
                            @change="toggleBorrowerBirthdayWishes"
                            type="checkbox" id="sendBirthdayWishes">
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-else-if="activeTab === 'role' && permissions['ceoAssistant']" class="tab">
            <div class="tab-header d-flex align-items-center">
              Roles & Permissions
              <div class="ml-auto">
                <button :disabled="ui.roleSaving" class="btn bg-green" @click="updateRoles">
                  <span v-if="ui.roleSaving"
                        aria-hidden="true"
                        class="spinner-border spinner-border-sm"
                        role="status">
                  </span>
                  <span v-else>Update Permissions</span>
                </button>
              </div>
            </div>
            <div class="explain-action">
              Select the roles to assign permissions
            </div>
            <div class="role-list">
              <div v-for="(role, index) in roles" :key="index"
                   :class="{ active: selectedRoles.indexOf(role.name) >= 0 }"
                   class="col-12 role-list-item">
                <div class="row">
                  <div class="col-8">
                    <div class="d-flex">
                      <div class="form-group form-check">
                        <input :id="role.name+index+'-Main'" v-model="selectedRoles" :value="role.name"
                               class="form-check-input" type="checkbox">
                      </div>
                      <div>
                        <label :for="role.name+index+'-Main'" class="cursor-pointer">
                          <div class="role-title">
                            {{ role.name }}
                          </div>
                        </label>
                      </div>
                    </div>
                    <div v-show="role.toggle" class="row mx-5 mt-3">
                      <div v-for="(child, key) in role.childrens" :key="key" class="col-10 d-flex">
                        <div class="form-group form-check">
                          <input :id="child.name+index+'-Child-'+key" v-model="selectedRoles" :value="child.name"
                                 class="form-check-input" type="checkbox">
                        </div>
                        <div>
                          <label :for="child.name+index+'-Child-'+key" class="cursor-pointer">
                            <div class="role-title">
                              {{ child.name }}
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 d-flex justify-content-end">
                    <button class="btn btn-outline-light" @click="role.toggle = !role.toggle">
                      {{ role.toggle ? 'Hide' : 'Show' }} Permissions
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="activeTab === 'fees' && (permissions['feeCrud'] || permissions['feeSplit'])" class="tab">
            <div class="tab-header d-flex align-items-center">
              Fees
              <button v-if="agent.isGranted['edit']" class="btn btn-control ml-2"
                      @click="ui.modals.defaultFeesModal = true">
                <img alt="Edit Details" src="@/assets/icons/icon-edit-normal.svg">
              </button>
            </div>
            <fieldset v-if="fees.processorFee.length > 0" class="mt-3">
              <legend>
                Processor Fee
                <span class="cursor-pointer" name="showProcessorFee" parent>
                  <img alt="alert" src="@/assets/icons/No Requested Docs.svg">
                </span>
                <tippy
                  animateFill="false"
                  appendTo="parent"
                  arrow="true"
                  arrowType="round"
                  boundary="window"
                  hideOnClick="true"
                  interactive="true"
                  placement="right"
                  theme="light task-light" to="showProcessorFee" trigger="click"
                  zIndex="99">
                  <div class="card">
                    <div class="card-body text-left">
                      <p>
                        Examples:<br>
                        Total Due To Sales Person: <b>{{ convertToCurrency(fees.summary.dueToSalesPerson) }}</b>
                      </p>
                      <hr>
                      <p>
                        <strong>Flat Fee</strong> processor's bonus = $400 and CMC Bonus = $50<br><br>
                        Agent earnings = 5000 - (400+50) = <b>$4550</b><br>
                        {{ agent.name }} earnings: = <b>$400</b><br>
                        CMC earnings: = <b>$50</b>
                      </p>
                      <hr>
                      <p>
                        <strong>Percentage</strong> processor's bonus = 10% and CMC Bonus = 20%<br><br>
                        Agent earnings = 5000 - (5000 * 0.10) = <b>$4500</b><br>
                        {{ agent.name }} earnings: 500 - (500 * 0.20) = <b>$400</b><br>
                        CMC earnings: 500 * 0.20 = <b>$100</b><br>
                        <label class="text-danger mt-3">*Please notice that <b>Percentage</b> CMC Bonus taken out of
                          processor's earnings.</label>
                      </p>
                    </div>
                  </div>
                </tippy>
              </legend>
              <div class="table">
                <table class="table mt-0">
                  <thead>
                  <tr>
                    <th>Payment Split</th>
                    <th></th>
                    <th>Share</th>
                    <th>Amount</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(fee, index) in fees.processorFee" :key="index">
                    <td>{{ fee.name }}</td>
                    <td><span>{{ fee.position }}</span></td>
                    <td v-if="fee.type === 'Percentage'">{{ fee.bonus }}%</td>
                    <td v-if="fee.type === 'Basis Points'">{{ fee.bonus }}bps</td>
                    <td v-else>Fixed <span>~ {{ fee.amount }}</span></td>
                    <td>{{ convertToCurrency((fee.type === 'Percentage') ? fee.amount : fee.bonus) }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </fieldset>
            <fieldset v-if="fees.mloFee.length > 0 || fees.loaFee.length > 0" class="mt-3">
              <legend>
                MLO & LOA Fee
                <span class="cursor-pointer">
                  <img v-tippy="{ placement : 'right', arrow : true, arrowType : 'round' }"
                      :content="`Example: Loan amount = ${convertToCurrency(fees.summary.loanAmount)} <br> Origination = ${convertToCurrency(fees.summary.origination)}`"
                      alt="alert"
                      src="@/assets/icons/No Requested Docs.svg">
                </span>
              </legend>
              <div class="table">
                <table class="table mt-0">
                  <thead>
                  <tr>
                    <th>Payment Split</th>
                    <th></th>
                    <th>Share</th>
                    <th>Amount</th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ agent.name }}</td>
                      <td><span>{{ agent.position }}</span></td>
                      <td>{{ agent.feeBonus }}%</td>
                      <td>{{ convertToCurrency(agent.feeAmount) }}</td>
                    </tr>
                    <tr v-for="(fee, index) in fees.mloFee" :key="index + fee.name">
                      <td>{{ fee.name }}</td>
                      <td><span>{{ fee.position }} {{fee.type}}</span></td>
                      <td v-if="fee.type === 'Percentage'">{{ fee.bonus }}%</td>
                      <td v-else-if="fee.type === 'Basis Points'">{{ fee.bonus }}bps</td>
                      <td v-else>Fixed <span>~ {{ convertToCurrency(fee.amount) }}</span></td>

                      <td v-if="fee.type === 'Percentage'">{{ convertToCurrency(fee.amount) }}</td>
                      <td v-else-if="fee.type === 'Basis Points'">{{ convertToCurrency(fee.amount) }}</td>
                      <td v-else>{{ convertToCurrency(fee.bonus) }}</td>
                    </tr>
                    <tr v-for="(fee, index) in fees.loaFee" :key="index + fee.name">
                      <td>{{ fee.name }}</td>
                      <td><span>{{ fee.position }} {{fee.type}}</span></td>

                      <td v-if="fee.type === 'Percentage'">{{ fee.bonus }}%</td>
                      <td v-else-if="fee.type === 'Basis Points'">{{ fee.bonus }}bps</td>
                      <td v-else>Fixed <span>~ {{ convertToCurrency(fee.amount) }}</span></td>

                      <td v-if="fee.type === 'Percentage'">{{ convertToCurrency(fee.amount) }}</td>
                      <td v-else-if="fee.type === 'Basis Points'">{{ convertToCurrency(fee.amount) }}</td>
                      <td v-else>{{ convertToCurrency(fee.bonus) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </fieldset>
          </div>
          <div v-else-if="activeTab === 'limits' && details.role === 'Processor' && permissions['ceoAssistant']"
               class="tab">
            <div>
              <div class="tab-header d-flex align-items-center">
                Loans Limit
                <button v-if="agent.isGranted['edit']" class="btn btn-control ml-2"
                        @click="ui.modals.loansLimitModal = true">
                  <img alt="Edit Details" src="@/assets/icons/icon-edit-normal.svg">
                </button>
              </div>
              <div class="table">
                <table class="table table-assign">
                  <tbody>
                  <tr>
                    <td><span>Max loans per day</span></td>
                    <td>{{ loansLimit.maxPerDay }}</td>
                  </tr>
                  <tr>
                    <td><span>Max loans total</span></td>
                    <td>{{ loansLimit.maxTotal }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div>
              <div class="tab-header d-flex align-items-center">
                Who can assign
                <button v-if="agent.isGranted['edit']" class="btn btn-control ml-2"
                        @click="ui.modals.assignLoanModal = true">
                  <img alt="Edit Details" src="@/assets/icons/icon-edit-normal.svg">
                </button>
              </div>
              <div class="explain-action">
                If limit not exceeded. Otherwise only CEO and CEO Assistant would be able to do that.
              </div>
              <div class="table">
                <table class="table table-assign">
                  <tbody>
                  <tr v-for="(assignee, index) in loansLimit.assignee" :key="index">
                    <td>
                      <v-avatar :size="36" :src="agentPhoto(assignee.profileImage)" rounded/>
                    </td>
                    <td class="ps-2">{{ assignee.name }}</td>
                    <td><span>{{ assignee.position }}</span></td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div v-else-if="activeTab === 'payment'" class="tab">
            <div class="tab-header d-flex align-items-center" v-if="isGranted('ROLE_CEO_ASSISTANT')">
              Restrictions
              <button v-if="agent.isGranted['edit']" class="btn btn-control ml-2"
                      @click="ui.modals.restrictionsModal = true">
                <img alt="Edit Details" src="@/assets/icons/icon-edit-normal.svg">
              </button>
            </div>
            <div class="table" v-if="isGranted('ROLE_CEO_ASSISTANT')">
              <table class="table table-restrictions">
                <tbody>
                  <tr>
                    <td><span>Withdrawal Reserved</span></td>
                    <td>{{ convertToCurrency(payment.restrictions.wReserved) }}</td>
                  </tr>
                  <tr v-if="isGranted('ROLE_CEO_ASSISTANT')">
                    <td><span>Charge Admin Fee</span></td>
                    <td>
                      <input class="form-check-input" v-model="payment.restrictions.chargeAdminFee"
                             @change="toggleChargeAdminFee"
                             type="checkbox" id="flexCheckDefault">
                    </td>
                  </tr>
                  <tr v-if="agentHasRole(agent.roles, 'ROLE_PROCESSOR')">
                    <td><span>Enhanced Processing</span></td>
                    <td>
                      <input class="form-check-input" v-model="payment.restrictions.enhancedProcessing"
                             @change="toggleEnhancedProcessing"
                             type="checkbox" id="enhancedProcessing">
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="tab-header d-flex align-items-center">
              Payment Method
              <button v-if="agent.isGranted['edit']" class="btn btn-control ml-2"
                      @click="ui.modals.userPaymentModal = true">
                <img alt="Edit Details" src="@/assets/icons/icon-edit-normal.svg">
              </button>
            </div>
            <div class="table">
              <table class="table table-payment">
                <tbody>
                <tr>
                  <td><span>Bank Name</span></td>
                  <td>{{ payment.bankName ? payment.bankName : 'N/A' }}</td>
                </tr>
                <tr>
                  <td><span>Account Name</span></td>
                  <td>{{ payment.accountName ? payment.accountName : 'N/A' }}</td>
                </tr>
                <tr>
                  <td><span>Routing Number</span></td>
                  <td>{{ payment.routingNumber ? payment.routingNumber : 'N/A' }}</td>
                </tr>
                <tr>
                  <td><span>Account Number</span></td>
                  <td>{{ payment.accountNumber ? payment.accountNumber : 'N/A' }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-else-if="activeTab === 'w9'" class="tab">
            <div class="tab-header d-flex align-items-center">
              W9 Information
              <button v-if="agent.isGranted['edit']" class="btn btn-control ml-2"
                      @click="ui.modals.wNineInfoModal = true">
                <img alt="Edit Details" src="@/assets/icons/icon-edit-normal.svg">
              </button>
            </div>
            <div class="table">
              <table class="table table-w9">
                <tbody>
                <tr>
                  <td><span>W9 Payee</span></td>
                  <td>{{ w9Information.companyPayee ? w9Information.companyPayee : 'N/A' }}</td>
                </tr>
                <tr>
                  <td><span>Tax Classification</span></td>
                  <td>{{ w9Information.taxClassification ? w9Information.taxClassification : 'N/A' }}</td>
                </tr>
                <tr>
                  <td><span>Tax Id</span></td>
                  <td>{{ w9Information.taxId ? w9Information.taxId : 'N/A' }}</td>
                </tr>
                <tr>
                  <td><span>SSN</span></td>
                  <td>{{ w9Information.ssn | secureVisible }}</td>
                </tr>
                <tr>
                  <td><span>Address</span></td>
                  <td>{{ w9Information.address ? w9Information.address : 'N/A' }}</td>
                </tr>
                <tr>
                  <td><span>City</span></td>
                  <td>{{ w9Information.city ? w9Information.city : 'N/A' }}</td>
                </tr>
                <tr>
                  <td><span>State</span></td>
                  <td>{{ w9Information.state ? w9Information.state : 'N/A' }}</td>
                </tr>
                <tr>
                  <td><span>Zip</span></td>
                  <td>{{ w9Information.zip ? w9Information.zip : 'N/A' }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-else-if="activeTab === 'documents'" class="tab">
            <div class="tab-header d-flex align-items-center justify-content-between">
              Documents
              <button class="btn btn-outline-light" @click="ui.modals.userUploadDocumentsModal = true">
                <img alt="Upload" src="@/assets/icons/icon-upload.svg">
                Upload
              </button>
            </div>
            <div class="table">
              <table class="table table-documents">
                <thead>
                <tr>
                  <th>Documents</th>
                  <th>Type</th>
                  <th>Status</th>
                  <th>Submitted</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="documents.length === 0">
                  <td colspan="5">
                    <div class="modal-alert modal-alert_advice text-center">
                      No Records Found
                    </div>
                  </td>
                </tr>
                <tr v-for="(document, index) in documents" :key="index">
                  <td class="text-decoration-underline">{{ document.name }}</td>
                  <td>{{ document.type }} <span v-if="document.type === 'Borrower' && document.borrower">: {{ document.borrower }}</span></td>
                  <td>
                    <button v-if="isGranted('ROLE_CEO')"
                            v-tippy="{arrow: true, placement: 'top-center'}"
                            :class="docClass(document.status)"
                            class="btn btn-sm"
                            content="Change Document Status"
                            @click="ui.modals.changeDocumentStatus = true, selectedDocument=document">
                      {{ document.status }}
                    </button>
                    <button v-else :class="docClass(document.status)" class="btn btn-sm">{{ document.status }}</button>
                  </td>
                  <td>{{ formatDate(document.createdAt, 'MMM D, Y, hh:mm A') }}</td>
                  <td>
                    <div :name="'document' + index" class="btn-modal">
                      <img alt="More button" class="icon-more" src="@/assets/icons/icon-more.svg">
                    </div>
                    <tippy :to="'document' + index"
                           animateFill="false"
                           boundary="window"
                           class="table-menu"
                           distance="2"
                           interactive="true"
                           placement="bottom-end"
                           theme="light"
                           trigger="click"
                           zIndex="1039">
                      <div class="table-menu-block">
                        <ul>
                          <li class="action-label">Actions</li>
                          <li v-if="document.isGranted['view']" class="cursor-pointer">
                            <a href="#" @click="preview(document.id)">
                              <img alt="Preview" src="@/assets/icons/icon-preview.svg">
                              Preview
                            </a>
                          </li>
                          <li v-if="document.isGranted['view']" class="cursor-pointer">
                            <a href="#" @click="download(document.id)">
                              <img alt="Download" src="@/assets/icons/icon-download.svg">
                              Download
                            </a>
                          </li>
                          <li v-if="document.type !== 'Loan' && document.isGranted['delete'] && !document.name.includes('EULA')" class="cursor-pointer">
                            <a href="#" @click="remove(document.id)">
                              <img alt="Delete" src="@/assets/icons/icon-delete.svg">
                              Delete
                            </a>
                          </li>
                        </ul>
                      </div>
                    </tippy>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-else-if="activeTab === 'notes' && (isGranted('ROLE_CEO_ASSISTANT') || isGranted('ROLE_ANALYST'))" class="tab">
            <div class="tab-header d-flex align-items-center justify-content-between">
              Notes
              <button class="btn bg-green" @click="ui.modals.userNoteModal = true">Create Note</button>
            </div>
            <div class="modal-alert modal-alert_advice text-center" v-if="notes.length === 0">
              No Notes Found
            </div>
            <div class="row" v-else>
              <div class="col-12 usernote" v-for="note in notes" :key="note.id">
                <div class="row">
                  <div class="col-10">
                    <div class="usernote__note" v-html="note.description"></div>
                    <div class="usernote__assigner mt-2">
                      <img :src="agentPhoto(note.addedBy.photo)" class="me-2" alt="">
                      <span class="usernote__assigner__name">
                        {{ note.addedBy.name }}
                      </span>
                      <span class="usernote__assigner__added">
                        added a note
                      </span>
                    </div>
                  </div>
                  <div class="col-2 p-0 usernote__textend">
                    <span class="date-label">
                      {{ formatDate(note.createdAt, 'MM/DD/YYYY hh:mm a') }}
                    </span>
                    <div class="buttons-section">
                      <button class="btn btn-control" @click="editUserNote(note)">
                        <img alt="Edit" src="@/assets/icons/icon-edit-normal.svg">
                      </button>
                      <button class="btn btn-control" @click="deleteUserNote(note)">
                        <img alt="Delete" src="@/assets/icons/icon-delete.svg">
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="activeTab === 'comp' && showCompSetting" class="tab">
            <div class="d-flex justify-content-between">
              <div class="tab-header d-flex align-items-center">
                Compensation
                <span class="text-secondary mx-2" style="font-size:10px">
                  Compensation/Margin for each lead source
                </span>
                <span>
                  <button class="btn btn-control"
                          v-tippy="{arrow: true, placement: 'top-center'}"
                          :content="'This can only be adjusted every 90 days. <br> Next Update: ' + compNextUpdate">
                    <img alt="Comp info" src="@/assets/icons/icon-info.svg">
                  </button>
                </span>
              </div>
              <div>
                <button type="button" class="btn bg-green" v-if="showCompModal" @click="ui.modals.compensationModal = true">
                  {{ partners.length > 0 ? 'Edit Comp Plans' : 'Add Comp Plans' }}
                </button>
              </div>
            </div>
            <div v-if="agent.isNewCRM" class="alert alert-info mt-4">
              Comp settings moved to new CRM. <br>
              Please go to <a href="https://app.vision-crm.com/profile#Compensation%20Plans" target="_blank">Vision CRM</a> to manage comp settings.
            </div>

            <div class="table mt-3">
              <table class="table table-equal">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th class="text-center">Compensation</th>
                    <th class="text-center">Min. compensation</th>
                    <th class="text-center">Max. compensation</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="partners.length === 0">
                    <td colspan="5">
                      <div class="modal-alert modal-alert_advice text-center">
                        No Records Found
                      </div>
                    </td>
                  </tr>
                  <tr v-for="(partner, index) in partners" :key="index">
                    <td>{{ partner.name }}</td>
                    <td class="text-center">{{ partner.comp }}%</td>
                    <td class="text-center">{{ convertToCurrency(partner.minComp) }}</td>
                    <td class="text-center">{{ convertToCurrency(partner.maxComp) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-else-if="activeTab === 'review'" class="tab">
            <div class="row">
              <div class="col-7 video-block">
                <div class="d-flex justify-content-between border-bottom pb-3">
                  <div class="d-flex">
                    <div class="tab-header border-0 pb-0">
                      Video
                    </div>
                    <div class="mx-2 form-check form-switch" v-if="details.videoUrl">
                      <input
                        id="blockfive-toggle"
                        class="form-check-input"
                        type="checkbox"
                        v-model="details.showVideoLendingPage"
                        @change="toggleVideoShow"
                      />
                      <label class="form-check-label" for="blockfive-toggle">Show on lending page</label>
                    </div>
                  </div>
                  <div>
                    <button type="button" class="btn bg-green" @click="ui.modals.videoModal = true" v-if="!details.videoUrl">
                      Add Video
                    </button>
                    <div class="d-flex" v-else>
                      <button class="btn btn-control" @click="ui.modals.videoModal = true" >
                        <img alt="Edit" src="@/assets/icons/icon-edit-normal.svg">
                      </button>
                      <button class="btn btn-control" @click="deleteVideo">
                        <img alt="Delete" src="@/assets/icons/icon-delete.svg">
                      </button>
                    </div>
                  </div>
                </div>
                <div class="modal-alert modal-alert_advice text-center mt-3" v-if="!details.videoUrl">
                  No Video Found
                </div>
                <div class="row" v-else>
                  <div class="col-12 py-4">
                    <div class="profile-video">
                      <video controls
                        :poster="details.videoThumbnail"
                        :src="details.videoUrl"
                        class="video-player"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-5 review-block">
                <div class="d-flex justify-content-between border-bottom pb-3">
                  <div class="tab-header border-0 pb-0">
                    Reviews
                  </div>
                  <div>
                    <button type="button" class="btn bg-green" :disabled="reviews.length >= 3" @click="ui.modals.reviewModal = true">
                      Add Review
                    </button>
                  </div>
                </div>
                <div class="modal-alert modal-alert_advice text-center mt-3 mx-3" v-if="reviews.length === 0">
                  No Reviews Found
                </div>
                <div class="row" v-else>
                  <div class="col-12 usernote py-4" v-for="(review, index) in reviews" :key="index" :class="{active: review.toggle}">
                    <div class="row">
                      <div class="col-10" @click="review.toggle = !review.toggle">
                        <div class="d-flex cursor-pointer">
                          <label :for="review.clientName+index+'-Main'">
                            <div class="usernote__assigner ml-3">
                              <span class="usernote__assigner__name">
                                <strong>{{ review.clientName }}</strong>
                              </span>
                              <span class="usernote__assigner__added ml-2">
                                Borrower via {{ review.source}}
                              </span>
                            </div>
                          </label>
                        </div>
                        <div class="row mt-3" v-show="review.toggle">
                          <div class="usernote__note ml-4" v-html="review.reviewText"></div>
                        </div>
                        <!-- -->
                      </div>
                      <div class="col-2 p-0 usernote__textend">
                        <div class="buttons-section">
                          <button class="btn btn-control" @click="editReview(review)">
                            <img alt="Edit" src="@/assets/icons/icon-edit-normal.svg">
                          </button>
                          <button class="btn btn-control" @click="deleteReview(review.id)">
                            <img alt="Delete" src="@/assets/icons/icon-delete.svg">
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-header d-flex align-items-center">
              Metrics &amp; Stats
              <button v-if="agent.isGranted['edit']" class="btn btn-control ml-2"
                      @click="ui.modals.userMetricsModal = true">
                <img alt="Edit Details" src="@/assets/icons/icon-edit-normal.svg">
              </button>
            </div>
            <div class="table">
              <table class="table table-payment">
                <tbody>
                <tr>
                  <td><span>Meta Pixel ID</span></td>
                  <td>{{ details.metaPixelId }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <transition mode="out-in" name="component-fade">
      <user-card-modal v-if="ui.modals.cardModal === true"
                       :userId='parseInt(agent.id)'
                       @close="ui.modals.cardModal = false"/>
    </transition>

    <transition mode="out-in" name="component-fade">
      <change-password-modal v-if="ui.modals.changePasswordModal === true"
                             :userId='parseInt(agent.id)'
                             @close="ui.modals.changePasswordModal = false"/>
    </transition>

    <transition mode="out-in" name="component-fade">
      <mlo-permissions-modal v-if="ui.modals.mloPermissionsModal === true"
                             @close="ui.modals.mloPermissionsModal = false"/>
    </transition>

    <transition mode="out-in" name="component-fade">
      <default-fees-modal v-if="ui.modals.defaultFeesModal === true"
                          :agent="agent"
                          @close="ui.modals.defaultFeesModal = false"
                          @success="getAgent"/>
    </transition>

    <transition mode="out-in" name="component-fade">
      <loans-limit-modal v-if="ui.modals.loansLimitModal === true"
                         :agent="agent"
                         :loansLimit="loansLimit"
                         @close="ui.modals.loansLimitModal = false"
                         @success="getAgent"/>
    </transition>

    <transition mode="out-in" name="component-fade">
      <assign-loan-modal v-if="ui.modals.assignLoanModal === true"
                         :agent="agent"
                         :allAgents="loansLimit.allAgents"
                         :assignee="loansLimit.assignee"
                         @close="ui.modals.assignLoanModal = false"
                         @createUser="ui.modals.createUserModal = true"
                         @success="getAgent"/>
    </transition>

    <transition mode="out-in" name="component-fade">
      <user-payment-modal v-if="ui.modals.userPaymentModal === true"
                          :agent="agent"
                          :payment="payment"
                          @close="ui.modals.userPaymentModal = false"
                          @success="getAgent"/>
    </transition>

    <transition mode="out-in" name="component-fade">
      <restrictions-modal v-if="ui.modals.restrictionsModal === true"
                          :agent="agent"
                          :restrictions="payment.restrictions"
                          @close="ui.modals.restrictionsModal = false"
                          @success="getAgent"/>
    </transition>

    <transition mode="out-in" name="component-fade">
      <w-nine-info-modal v-if="ui.modals.wNineInfoModal === true"
                         :agent="agent"
                         @close="ui.modals.wNineInfoModal = false"
                         @success="getAgent"/>
    </transition>

    <transition mode="out-in" name="component-fade">
      <user-upload-documents-modal v-if="ui.modals.userUploadDocumentsModal === true"
                                   :types="documentTypes"
                                   :userId='parseInt(agent.id)'
                                   @close="ui.modals.userUploadDocumentsModal = false"
                                   @success="getAgent"
      />
    </transition>

    <transition mode="out-in" name="component-fade">
      <add-new-user v-if="ui.modals.createUserModal === true"
                    @close="ui.modals.createUserModal = false"/>
    </transition>

    <transition mode="out-in" name="component-fade">
      <edit-user-modal v-if="ui.modals.editUserModal === true"
                       @close="ui.modals.editUserModal = false"
                       @success="getAgent"/>
    </transition>

    <transition mode="out-in" name="component-fade">
      <edit-personal-details-modal v-if="ui.modals.editPersonalModal === true"
                       :personal="personals"
                       :dob="agent.dob"
                       @close="ui.modals.editPersonalModal = false"
                       @success="getAgent"/>
    </transition>

    <transition mode="out-in" name="component-fade">
      <change-document-status v-if="ui.modals.changeDocumentStatus === true"
                              :document="selectedDocument"
                              :statuses="documentStatuses"
                              @close="ui.modals.changeDocumentStatus = false, selectedDocument = {}"
                              @success="getAgent"/>
    </transition>
    <transition mode="out-in" name="component-fade">
      <user-note-modal v-if="ui.modals.userNoteModal === true"
                      :noteData="selectedNote"
                      @close="closeUserNoteModal"
                      @success="getAgent"/>
    </transition>

    <!--  Compensation modal  -->
    <transition mode="out-in" name="component-fade">
      <compensation-modal v-if="ui.modals.compensationModal === true && showCompModal"
                          :partners="partners"
                          :is-refi="isIrefiAgent"
                          @close="closeCompModal"/>
    </transition>

    <!-- Review modal  -->
    <transition mode="out-in" name="component-fade">
      <review-modal v-if="ui.modals.reviewModal === true"
                          :review="selectedReview"
                          @close="closeReviewModal"
                          @success="successReview"/>
    </transition>

    <!-- Video modal  -->
    <transition mode="out-in" name="component-fade">
      <video-modal v-if="ui.modals.videoModal === true"
                          :details="details"
                          :agentId='parseInt(agent.id)'
                          @close="closeVideoModal"
                          @success="successVideo"/>
    </transition>

    <transition mode="out-in" name="component-fade">
      <user-metrics-modal v-if="ui.modals.userMetricsModal === true"
                          :agent="agent"
                          :meta-pixel-id="details.metaPixelId"
                          @close="ui.modals.userMetricsModal = false"
                          @success="getAgent"/>
    </transition>
  </div>
</template>

<script>
import UserCardModal from "./modals/UserCardModal";
import ChangePasswordModal from "./modals/ChangePasswordModal";
import MloPermissionsModal from "./modals/MloPermissionsModal";
import DefaultFeesModal from "./modals/DefaultFeesModal";
import LoansLimitModal from "./modals/LoansLimitModal";
import AssignLoanModal from "./modals/AssignLoansModal";
import UserPaymentModal from "./modals/UserPaymentModal";
import WNineInfoModal from "./modals/WNineInfoModal";
import UserUploadDocumentsModal from "./modals/UserUploadDocuments";
import AddNewUser from "./modals/AddNewUser";
import EditUserModal from "./modals/EditUserModal";
import ChangeDocumentStatus from "./modals/ChangeDocumentStatus";
import RestrictionsModal from "./modals/RestrictionsModal";
import Multiselect from 'vue-multiselect';
import UserNoteModal from "./modals/UserNoteModal";
import CompensationModal from "./modals/AgentCompensationModal";
import ReviewModal from "./modals/ReviewModal";
import EditPersonalDetailsModal from "./modals/EditPersonalDetailsModal";
import VideoModal from "./modals/VideoModal";
import UserMetricsModal from "@/views/users/modals/UserMetricsModal";

export default {
  name: 'UserDetails',
  components: {
    EditUserModal, AddNewUser, UserUploadDocumentsModal,
    WNineInfoModal, UserPaymentModal, AssignLoanModal,
    LoansLimitModal, DefaultFeesModal, MloPermissionsModal,
    ChangePasswordModal, UserCardModal, ChangeDocumentStatus,
    Multiselect, RestrictionsModal, UserNoteModal, CompensationModal,
    ReviewModal, EditPersonalDetailsModal, VideoModal, UserMetricsModal
  },
  data() {
    return {
      permissions: [],
      agent: Object,
      account: {},
      details: Object,
      personals: Object,
      payment: Object,
      w9Information: Object,
      loansLimit: Object,
      fees: Object,
      documents: [],
      documentTypes: [],
      documentStatuses: [],
      activeTab: 'details',
      roles: [],
      notes: [],
      reviews: [],
      ui: {
        tab: {
          showButtons: false
        },
        modals: {
          cardModal: false,
          changePasswordModal: false,
          mloPermissionsModal: false,
          defaultFeesModal: false,
          loansLimitModal: false,
          assignLoanModal: false,
          userPaymentModal: false,
          userMetricsModal: false,
          wNineInfoModal: false,
          userUploadDocumentsModal: false,
          createUserModal: false,
          editUserModal: false,
          editPersonalModal: false,
          statusAlert: false,
          changeDocumentStatus: false,
          restrictionsModal: false,
          userNoteModal: false,
          compensationModal: false,
          reviewModal: false,
          videoModal: false
        },
        loading: false,
        resetPassLoading: false,
        reset2faLoading: false,
        unlockAccount: false,
        roleSaving: false,
        showUpdateAvatar: false
      },
      profileVisibilities: [],
      selectedDocument: {},
      selectedRoles: [],
      selectedNote: { id: 0, description: ''},
      selectedReview: {},
      partners: [],
      compNextUpdate: '',
      partnerId: null
    }
  },
  computed: {
    showCompSetting() {
      if (this.isGranted('ROLE_FUNDER') || this.isGranted('ROLE_CEO_ASSISTANT')) {
        return true;
      }

      if (this.isIrefiAgent) {
        return false;
      }

      if (this.$route.params.id == this.$store.getters.getUserId) {
        return true;
      }

      if (this.isGranted('ROLE_ANALYST')) {
        return false;
      }

      return false;
    },
    isIrefiAgent() {
      return this.roles.filter(({name, status}) => name === 'ROLE_IREFI_AGENT' && status === true).length > 0
    },
    showCompModal() {
      return !this.agent.isNewCRM && (
        (this.partners.filter((item) => item.isEditable === true).length > 0 || this.partners.length === 0)
        || this.isGranted('ROLE_CEO_ASSISTANT')
      );
    },
    disabledCompAction() {
      return (partner) => {
        if (this.isGranted('ROLE_CEO_ASSISTANT')) {
          return false;
        }

        return !partner.isEditable;
      }
    },
    showOwnTab() {
      if (this.$route.params.id == this.$store.getters.getUserId) {
        return true;
      }
      return false;
    }
  },
  methods: {
    toggleTab(name) {
      this.activeTab = name;
    },
    getRateQuoteFunnelUrl(firstName, lastName) {
      return `${process.env.VUE_APP_API_URL}/rate-quote-funnel/${this.$route.params.id}/${firstName}${lastName}`
    },
    getAgent() {
      this.ui.loading = true;
      this.$http.get(`/api/v1/staff/profile/${this.$route.params.id}`)
        .then((res) => {
          this.permissions = res.data.permissions
          this.profileVisibilities = res.data.profileVisibilities
          this.agent = res.data.agent
          this.$store.dispatch('UPDATE_NEW_CRM', this.agent.isNewCRM)
          this.details = res.data.details
          this.personals = res.data.personals
          this.details.rateQuoteFunnelUrl = this.getRateQuoteFunnelUrl(this.agent.firstName, this.agent.lastName)
          this.payment = res.data.payment
          this.account = res.data.account
          this.w9Information = res.data.w9Information
          this.documents = res.data.documents
          this.fees = res.data.fees
          this.roles = res.data.roles
          this.notes = res.data.notes
          this.documents.map((el) => {
            el.showMenu = false;
          })
          this.documentTypes = res.data.documentTypes
          this.documentStatuses = res.data.documentStatuses
          this.loansLimit = res.data.loansLimit
          this.reviews = res.data.reviews

          this.setSelectedRoles();
          this.calculateAgentFeeBonus();
        })
        .catch((err) => {
          this.alertError(err.response.statusText)
        })
        .finally(() => {
          this.ui.loading = false;
        })
    },
    changeVisiblity(option) {
      let oldVisibility = this.agent.visibility;
      this.agent.visibility = option
      this.ui.loading = true;
      let formData = new FormData()
      formData.append('visiblity', this.agent.visibility ? this.agent.visibility.value : '')

      this.$http.post(`/api/v1/staff/change-visiblity/${this.agent.id}`, formData)
        .then((res) => {
          this.alertSuccess(res.data.message)
        })
        .catch((err) => {
          this.alertError(err.response.data ? err.response.data.message : err.response.statusText)
          this.agent.visibility = oldVisibility;
        })
        .finally(() => {
          this.ui.loading = false;
        })
    },
    mailtoCall() {
      location.href = `mailto:${this.agent.email}`
    },
    telCall() {
      location.href = `tel:${this.agent.phone}`
    },
    download(docId) {
      if (docId > 0) {
        this.goto(this.backendUrl(`/mlo/document/download/${docId}?disposition_type=true`))
      }
    },
    preview(docId) {
      if (docId > 0) {
        window.open(this.backendUrl(`/mlo/document/download/${docId}`), '_blank');
      }
    },
    remove(docId) {
      if (docId > 0 && confirm("Are you sure?")) {
        this.$http.delete(`/api/v1/documents/delete/${docId}`)
          .then((res) => {
            this.alertSuccess(res.data.message)
            this.getAgent()
          })
          .catch((err) => {
            this.alertError(err.response.statusText)
          })
      }
    },
    docClass(status) {
      switch (status.toLowerCase()) {
        case 'pending':
          return "btn-secondary";

        case 'accepted':
          return "btn-success";

        case 'declined':
          return "btn-danger";

        default:
          return "btn-warning";

      }
    },
    resetPassword() {
      this.ui.resetPassLoading = true
      this.$http.get(`/api/v1/staff/reset-password/${this.agent.id}`)
        .then((res) => {
          this.alertSuccess(res.data.message, 3700);
        })
        .catch((err) => {
          this.alertError(err.response.statusText)
        })
        .finally(() => {
          this.ui.resetPassLoading = false
        })
    },
    reset2fa() {
      if (confirm("Are you sure?")) {
        this.ui.reset2faLoading = true
        this.$http.get(`/api/v1/staff/reset-2fa/${this.agent.id}`)
          .then((res) => {
            this.alertSuccess(res.data.message, 3700);
          })
          .catch((err) => {
            this.alertError(err.response.statusText)
          })
          .finally(() => {
            this.ui.reset2faLoading = false
          })
      }
    },
    unlockAccount() {
      if (confirm("Are you sure?")) {
        this.ui.unlockAccount = true
        this.$http.get(`/api/v1/staff/unlock-account/${this.agent.id}`)
          .then((res) => {
            this.alertSuccess(res.data.message, 3700);
          })
          .catch((err) => {
            this.alertError(err.response.statusText)
          })
          .finally(() => {
            this.ui.unlockAccount = false
          })
      }
    },
    updateRoles() {
      this.ui.roleSaving = true
      let formData = new FormData()
      this.selectedRoles.forEach((value) => {
        formData.append('roles[]', value)
      });

      this.$http.post(`/api/v1/staff/roles/${this.agent.id}`, formData)
        .then((res) => {
          this.alertSuccess(res.data.message);
          this.$root.$emit('updateUser')
          this.getAgent();
        })
        .catch((err) => {
          this.alertError(err.response.statusText)
        })
        .finally(() => {
          this.ui.roleSaving = false
        })
    },
    setSelectedRoles() {
      this.selectedRoles = [];
      this.roles.forEach((el) => {
        if (this.selectedRoles.indexOf(el.name) < 0 && el.status === true) {
          this.selectedRoles.push(el.name)
        }

        el.childrens.forEach((cel) => {
          if (this.selectedRoles.indexOf(cel.name) < 0 && cel.status === true) {
            this.selectedRoles.push(cel.name)
          }
        })
      });
    },
    cropUploadSuccess(jsonData) {
      this.agent.profileImage = jsonData.data + '?cache=' + Math.floor(Math.random() * 11)
      if (this.$store.getters.getUserId == this.agent.id) {
        this.$store.dispatch('SET_USER_PHOTO', this.agent.profileImage)
      }
      setTimeout(() => {
        this.$refs.avatar.setStep(1)
        this.ui.showUpdateAvatar = false
      }, 1000)
    },
    calculateAgentFeeBonus() {
      let bonusTotal = 0;
      let hireBonusesTotal = 0;
      let origination = this.fees.summary.origination;
      let anotherSideEarnings = {};

      if (this.fees.mloFee.length > 0) {
        anotherSideEarnings = this.fees.mloFee[0]
        this.fees.mloFee.forEach((value) => {
          if (value.type === "Percentage") {
            bonusTotal += parseFloat(value.bonus)
          } else if (value.type === "Basis Points") {
            hireBonusesTotal += parseFloat(value.amount);
          } else {
            hireBonusesTotal += parseFloat(value.bonus);
          }
        })
      }

      if (this.fees.loaFee.length > 0) {
        this.fees.loaFee.forEach((value) => {
          if (value.type === "Percentage") {
            bonusTotal += parseFloat(value.bonus)
          } else if (value.type === "Basis Points") {
            hireBonusesTotal += parseFloat(value.amount);
          } else {
            hireBonusesTotal += parseFloat(value.bonus);
          }
        })
      }

      this.agent.feeBonus = parseFloat(100 - bonusTotal);
      this.agent.feeAmount = parseFloat(origination * (this.agent.feeBonus / 100));
      let mloIsMainReceiver = this.agent.feeBonus > anotherSideEarnings.bonus

      if (anotherSideEarnings) {
        if (mloIsMainReceiver) {
          this.agent.feeAmount = parseFloat(this.agent.feeAmount - hireBonusesTotal)
        } else {
          anotherSideEarnings.amount = parseFloat(anotherSideEarnings.amount - hireBonusesTotal)
        }
      }
    },
    toggleChargeAdminFee() {
      let formData = new FormData();
      formData.append('chargeAdminFee', this.payment.restrictions.chargeAdminFee)
      this.$http.post(`/api/v1/staff/profile/toggle-admin-fee/${this.agent.id}`, formData)
        .then(() => {
          this.pushAlert('success', 'Agent restrictions successfully updated.')
        })
        .catch(() => {
          this.pushAlert('error', 'Something went wrong.')
        })
    },
    toggleStateVisibility() {
      let formData = new FormData();
      formData.append('availableForAllStates', this.account.availableForAllStates)
      this.$http.post(`/api/v1/staff/profile/toggle-state-visibility/${this.agent.id}`, formData)
        .then(() => {
          this.pushAlert('success', 'Account successfully updated.')
        })
        .catch(() => {
          this.pushAlert('error', 'Something went wrong.')
        })
    },
    toggleVideoShow() {
      if (confirm("Are you sure?") === true) {
        let formData = new FormData();
        formData.append('toggleShowVideo', this.details.showVideoLendingPage)
        this.$http.post(`/api/v1/staff/toggle-show-video/${this.agent.id}`, formData)
          .catch(() => {
            this.pushAlert('error', 'Something went wrong.')
          })
      }
    },
    toggleBorrowerBirthdayWishes() {
      let formData = new FormData();
      formData.append('borrowerBirthdayWishes', this.account.borrowerBirthdayWishes)
      this.$http.post(`/api/v1/staff/profile/toggle-borrower-birthday-wishes/${this.agent.id}`, formData)
        .then(() => {
          this.pushAlert('success', 'Account successfully updated.')
        })
        .catch(() => {
          this.pushAlert('error', 'Something went wrong.')
        })
    },
    toggleEnhancedProcessing() {
      let formData = new FormData();
      formData.append('enhancedProcessing', this.payment.restrictions.enhancedProcessing)
      this.$http.post(`/api/v1/staff/profile/toggle-enhanced-processing/${this.agent.id}`, formData)
        .then(() => {
          this.pushAlert('success', 'Agent account successfully updated')
        })
        .catch(() => {
          this.pushAlert('error', 'Something went wrong.')
        })
    },
    closeUserNoteModal() {
      this.selectedNote = {id: 0, description: ''};
      this.ui.modals.userNoteModal = false
    },
    editUserNote(note) {
      this.selectedNote = note
      this.ui.modals.userNoteModal = true
    },
    deleteUserNote(note) {
      if (confirm("Are you sure?")) {
        this.ui.loading = true;
        this.$http
          .delete(`/api/v1/user/note/${note.id}/delete`)
          .then(() => this.getAgent())
          .catch((err) => {
            this.alertError(err.response.statusText);
            this.ui.loading = false;
          });
      }
    },
    agentHasRole(roles, roleName) {
      if (typeof roles === 'object' && !Array.isArray(roles)) {
        roles = Object.values(roles)
      }
      return roles.includes(roleName)
    },
    getAgentPartners() {
      this.$http.get(`/api/v1/corr-pricer/${this.$route.params.id}/get-agent-partners`)
        .then((res) => {
          this.partners = res.data.partners.filter(({irefiEnabled}) => irefiEnabled !== true);
          this.compNextUpdate = res.data.nextUpdate
        }).catch((err) => {
          this.alertError(err.response.statusText);
        });
    },
    deletePartner(id) {
      if (confirm("Delete partner?")) {
        this.$http.delete(`/api/v1/corr-pricer/${id}/delete`)
          .then(() => {this.getAgentPartners()})
          .catch((err) => {
            this.alertError(err.response.data ? err.response.data.message : err.response.statusText);
          })
      }
    },
    closeCompModal(updateList = false) {
      if (updateList) {
        this.getAgentPartners()
      }

      this.ui.modals.compensationModal = false;
    },
    closeReviewModal() {
      this.selectedReview = {};
      this.ui.modals.reviewModal = false;
    },
    successReview(review) {
      this.closeReviewModal();
      if (this.reviews.filter(({id}) => review.id === id).length) {
        this.reviews = this.reviews.map((el) => {
          if (el.id === review.id) {
            el = review;
          }
          return el;
        });
        return;
      }
      this.reviews.push(review);
    },
    deleteReview(reviewId) {
      if (confirm("Are you sure?") === true) {
        this.$http.delete(`/api/v1/reviews/${reviewId}/delete`)
        .then(() => {
          this.reviews = this.reviews.filter(({id}) => id !== reviewId);
        })
        .catch((err) => {
          this.alertError(err.response.data ? err.response.data.message : err.response.statusText);
        })
      }
    },
    editReview(review) {
      this.selectedReview = review;
      this.ui.modals.reviewModal = true;
    },
    closeVideoModal() {
      this.ui.modals.videoModal = false;
    },
    successVideo() {
      this.closeVideoModal();
      this.getAgent();
    },
    deleteVideo() {
      if (confirm("Are you sure?") === true) {
        this.$http.delete(`/api/v1/staff/video/${this.$route.params.id}/delete`)
        .then(() => {
          this.pushAlert('success', 'Video information successfully deleted')
          this.details.videoTitle = ''
          this.details.videoSubtitle = ''
          this.details.videoUrl = ''
          this.details.videoThumbnail = ''
          this.details.showVideoLendingPage = false
        })
        .catch((err) => {
          this.alertError(err.response.data ? err.response.data.message : err.response.statusText);
        })
      }
    }
  },
  created() {
    this.getAgent();
    this.getAgentPartners();
  },
  watch:{
    $route (){
      this.getAgent();
      this.getAgentPartners();
    }
  },
  filters: {
    formatText(value) {
      if (value) {
        return value;
      }
      return 'N/A';
    },
    secureVisible(value) {
      if (value) {
        let ssn = '';
        for (let i = 0; i < value.length; i++) {
          ssn += "*";
        }
        return ssn;
      }
      return 'N/A';
    }
  }
}
</script>

<style lang="scss" scoped>
#userDetails {
  .agent-actions::v-deep {
    .multiselect {
      height: 36px;
      min-height: 36px;
      width: 100%;
      min-width: 200px;
      width: fit-content;
      @media screen and (max-width: 568px) {
        max-width: 50%;
        min-width: 100%;
      }

      &.multiselect--active {
        .multiselect__tags {
          transition: all .3s;
          border: 1px solid rgba(38, 111, 229, 0.5);
          box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
        }
      }

      &__tags {
        min-height: 36px;
        max-height: 36px;
        padding: 6px 40px 0px 35px;
        transition: all .3s;
        border: 1px solid rgba(40, 167, 69, 0.5);
        color: #28A745;
        font-size: 14px;
        letter-spacing: 0;

        &:hover {
          border: 1px solid rgba(38, 111, 229, 0.2);
          box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
        }

        span {
          color: #28A745;
          font-size: 14px;
          letter-spacing: 0;
        }
      }

      &__select {
        min-height: 36px;
        max-height: 36px;

        &:before {
          top: 15%;
          position: relative;
          right: 0;
          color: #999;
          border: solid rgba(0, 0, 0, 0.46);
          margin-top: 4px;
          border-width: 0px 1px 1px 0;
          display: inline-block;
          padding: 3px;
          content: "";
          transform: rotate(44deg);
        }
      }

      &__single {
        color: rgba(0, 0, 0, 0.46);
        @media screen and (max-width: 568px) {
          font-size: 9px;
        }
      }

      &__option {
        padding: 10px 12px;
        min-height: 36px;
        transition: all .3s;
        line-height: 20px;
        font-size: 14px;
        color: #000;
        @media screen and (max-width: 568px) {
          font-size: 9px;
        }

        &--highlight {
          background: #f3f3f3;
          line-height: 20px;
          font-size: 14px;
          color: #000;
          @media screen and (max-width: 568px) {
            font-size: 9px;
          }
        }
      }
    }
  }

  .profile-visibility {

    .multiselect__tags {
      min-height: 36px;
      height: 36px;

      span {
        color: #28A745;
        font-size: 14px;
        letter-spacing: 0;
      }

    }

    .multiselect__placeholder {
      padding: 0;
    }

    .multiselect__select {
      min-height: 36px;
      height: 36px;
      padding: 0;

      &:before {
        top: 70%;
      }
    }

    .multiselect__option {
      height: 30px;
      min-height: 30px;
      max-height: 30px;
      padding: 8px;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 14px;
    }

    .multiselect__option--highlight {
      &:after {
        display: block;
        background: none;
        height: 30px;
        line-height: 30px;
      }
    }

    .multiselect__option--selected {
      background-color: rgba(0, 118, 255, .1);

      span {
        color: #000000;

      }

      &:after {
        color: #0078FF;
      }
    }
  }

  .page-header {
    border-bottom: 1px solid rgba(231, 232, 232, 1);
  }

  .page-content-grey {
    min-height: 100vh;
    background-color: rgba(246, 246, 246, 1);
  }

  .btn-outline-light {
    display: flex;
    align-items: center;
    height: 36px;
    min-height: 36px;
    max-height: 36px;
    color: rgba(0, 0, 0, .7);
    font-size: 14px;
    letter-spacing: 0;
    line-height: 14px;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 8px;
    opacity: 1;
    background: white;

    &:hover {
      color: rgba(0, 0, 0, .7);
      font-size: 14px;
      letter-spacing: 0;
      line-height: 14px;
      border: 1px solid rgba(0, 0, 0, .2);
      background-color: rgba(0, 0, 0, 0.05);
    }

    &.btn-change-password {
      max-width: 200px;
      width: 100%;
      margin-left: 12px;
    }
    &.btn-2fa {
      margin-left: 12px;
    }
    img {
      margin-right: 9px;
    }
  }

  .page-content {
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 2px 2px 40px 0 rgba(190, 205, 212, 0.1);
    margin: 24px 30px;
    padding: 36px 36px 60px 36px;

    .page-tabs {
      padding: 0;
      margin-top: 21px;
    }

    .tab {
      margin-top: 36px;

      &-header {
        color: rgba(0, 0, 0, 1);
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 30px;
        border-bottom: 1px solid rgba(231, 232, 232, 1);
        padding-bottom: 18px;

        .btn-outline-light {
          max-width: 122px;
        }
      }

      .explain-action {
        color: rgba(0, 0, 0, .46);
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24px;
        margin-top: 24px;
      }

      table {
        margin-top: 24px;

        &.about-table {
          tbody {
            tr {
              td:nth-child(2) {
                padding: 14px 5px 14px 5rem;
                white-space: normal;
              }
            }
          }
        }

        &.table-assign {
          tbody {
            tr {
              td:first-child {
                width: 4%;
              }

              td:nth-child(2) {
                width: 20%;
              }
            }
          }
        }

        &.table-payment {
          tbody {
            tr {
              td:first-child {
                width: 20%;
              }
            }
          }
        }

        &.table-restrictions {
          border-bottom: 1px solid #e7e8e8;
          margin-bottom: 2rem;
          tbody {
            tr {
              td:first-child {
                width: 20%;
              }
            }
          }
        }

        &.table-account {
          border-bottom: 1px solid #e7e8e8;
          margin-bottom: 2rem;
          tbody {
            tr {
              td:first-child {
                width: 30%;
              }
            }
          }
        }

        &.table-w9 {
          tbody {
            tr {
              td {
                &:first-child {
                  width: 23%;
                }
              }
            }
          }
        }

        &.table-personal {
          tbody {
            tr {
              td {
                &:first-child {
                  width: 23%;
                }
              }
            }
          }
        }

        &.table-documents {
          tbody {
            tr {
              cursor: pointer;
              transition: all .3s;

              &:hover {
                background-color: rgba(0, 0, 0, .05);

                .btn-modal {
                  opacity: 1;
                }
              }

              td {
                padding: 8px 0;
                height: 48px;

                &:first-child {
                  width: 40%;
                }

                &:nth-child(2) {
                  width: 20%;
                  padding-right: 8px;
                }

                &:nth-child(3) {
                  width: 15%;
                }

                .btn-control {
                  height: 32px;
                  width: 32px;
                }
              }

              .btn-modal {
                opacity: 0;
                transition: all .3s;
                outline: none;

                &.tippy-active {
                  opacity: 1;
                  border-radius: 8px;
                  background-color: rgba(0, 0, 0, 0.05);
                }
              }
            }
          }
        }

        thead {
          th {
            &:first-child {
              padding-left: 12px;
            }
          }
        }

        tbody {
          tr {
            padding: 0;
            vertical-align: middle;

            td {
              padding: 14px 0;

              &:first-child {
                padding-left: 12px;
              }

              span {
                color: rgba(0, 0, 0, .7);
                font-size: 14px;
                letter-spacing: 0;
                line-height: 20px;
              }

              a {
                color: rgba(1, 122, 255, 1);
                font-size: 14px;
                letter-spacing: 0;
                line-height: 20px;
                text-underline: none;
              }
            }
          }
        }
      }

      .role-list {
        margin-top: 18px;

        &-item {
          padding: 24px 18px;
          border-bottom: 1px solid rgba(231, 232, 232, 1);
          transition: all .3s;

          &.active {
            border-radius: 4px;
            background-color: rgba(38, 111, 229, .06);
            border-bottom: 1px solid #017AFF;

            .role-title,
            .role-explain {
              color: rgba(0, 0, 0, 1);
            }

            .btn-outline-light {
              border: 1px solid rgba(38, 111, 229, .5);
              border-radius: 8px;
              opacity: 1;
              color: rgba(0, 0, 0, 1);
              background-color: rgba(38, 111, 229, .06);
            }
          }

          .form-check {
            height: 18px;
            max-width: 18px;
            width: 100%;
            padding: 0;
            margin: 0;
            margin-right: 18px;

            .form-check-input {
              height: 18px;
              max-width: 18px;
              padding: 0;
              margin: 0;

              &:checked {
                background-color: #0d6efd;
                border-color: #0d6efd;
              }
            }

          }

          .role-title,
          .role-explain {
            color: rgba(0, 0, 0, .7);
            letter-spacing: 0;
            line-height: 14px;
            transition: all .3s;
          }

          .role-title {
            font-size: 18px;
            margin-top: 3px;

            .role-subtitle {
              font-size: 12px;
              margin-left:5px;
            }
          }

          .role-explain {
            font-size: 14px;
            margin-top: 10px;
          }

          .btn-outline-light {
            color: rgba(0, 0, 0, .7);
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 14px;
            border: 1px solid rgba(0, 0, 0, .2);
            border-radius: 8px;
            transition: all .3s;
          }
        }
      }
    }
  }

  .agent-actions {
    button {
      @media screen and (max-width: 768px) {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 36px;
        width: 36px;
        img {
          margin: 0;
          padding: 0;
        }
      }

      span {
        @media screen and (max-width: 768px) {
          display: none;
        }
      }
    }
  }

  .agent {
    border-bottom: 1px solid rgba(231, 232, 232, 1);
    padding-bottom: 36px;

    &-info {
      padding: 18px 0;
    }

    &-name {
      color: rgba(0, 0, 0, 1);
      font-size: 24px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 30px;
    }

    &-position {
      color: rgba(0, 0, 0, .7);
      font-size: 14px;
      letter-spacing: 0;
      line-height: 21px;
      margin-top: 12px;
    }

    .btn-control {
      height: 36px;
      width: 36px;
      border: 1px solid rgba(0, 0, 0, .2);
      border-radius: 8px;
      margin-right: 12px;

      &.edit-agent {
        position: absolute;
        top: 10px;
        left: 5px;
        height: 32px;
        width: 32px;
        background-color: rgba(255, 255, 255, 1);
        border-radius: 50%;
      }

      img {
        opacity: .7;
      }
    }

    .status {
      max-width: 150px;
      width: 100%;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 18px;
      padding: 2px 6px;
      border-radius: 8px;
      text-align: center;
      margin: 0 0 0 auto;

      &_published {
        color: rgba(0, 171, 52, 1);
        border: 1px solid rgba(0, 171, 52, 0.4);
        background-color: rgba(0, 171, 52, 0.05);
      }

      &_unpublished {
        color: rgba(0, 0, 0, 1);
        border: 1px solid rgba(0, 7, 46, 0.2);
        background-color: rgba(0, 7, 46, 0.05);
      }
    }

  }

  .select-icon {
    top: 8px;
    left: 12px;
    z-index: 99;
  }
}

.login-as {
  margin: 0 0 0 auto;
  max-width: max-content;

  img {
    @media screen and (max-width: 568px) {
      margin-right: 0 !important;
    }
  }

  span {
    @media screen and (max-width: 568px) {
      display: none;
    }
  }
}

.agent-name {
  div {
    display: inline-block;
    position: relative;
    margin-left: .5rem;
  }
}

.text-left {
  text-align: left !important;
}

.ml-auto {
  margin-left: auto !important;
}

::v-deep {
  .vue-image-crop-upload {
    .vicp-wrap {
      border-radius: 8px;
      padding: 35px;

      .vicp-close {
        right: 15px;
        top: 15px;

        .vicp-icon4 {
          &:before,
          &:after {
            background: lightgrey;
            box-shadow: none;
          }
        }
      }

      .vicp-step1 {
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          max-height: 36px;
          height: 36px;
          border-radius: 8px;
          opacity: 0.46;
          color: #000000;
          font-size: 12px;
          letter-spacing: 0;
          line-height: 14px;
          transition: all .3s;

          &:hover {
            opacity: 1;
          }
        }
      }

      .vicp-step2 {
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          max-height: 36px;
          height: 36px;
          border-radius: 8px;
          opacity: 0.46;
          color: #000000;
          font-size: 12px;
          letter-spacing: 0;
          line-height: 14px;
          transition: all .3s;

          &:hover {
            opacity: 1;
          }

          &.vicp-operate-btn {
            padding: 0 20px;
            background-color: #28A745;
            color: #FFFFFF;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0;
            opacity: 1;
            line-height: 22px;
            border-radius: 8px;
            text-decoration: none;
            @media screen and (max-width: 568px) {
              width: 120px;
              font-size: 10px;
            }

            &:hover {
              background-color: #1d9b3c;
              color: #FFFFFF;
            }
          }
        }

        .vicp-crop-right {
          width: 50%;
        }

        .vicp-preview-item-circle {
          img {
            border: 1px dashed rgba(0, 0, 0, .2);
          }
        }
      }

      .vicp-range {
        display: flex;
        align-items: center;

        input {
          border: none;
        }
      }
    }

    .vicp-operate {
      right: 30px;
    }

    .vicp-upload {
      width: 95%;
      margin: 0 auto;
    }

    .vicp-drop-area {
      width: 95%;
      margin: 20px auto;
    }

    .vicp-step2 .vicp-crop .vicp-crop-right .vicp-preview .vicp-preview-item.vicp-preview-item-circle {
      img {
        border: 1px dashed rgba(0, 0, 0, .2);
      }

    }
  }
}

.usernote {
  padding: 10px 24px;
  transition: all .3s;
  border-bottom: 1px solid #e7e8e8;

  &__textend {
    text-align: right;
    .date-label {
      opacity: .4;
      color: #000;
      font-size: 11px;
      letter-spacing: 0;
      line-height: 17px;
      text-align: right;
    }

    .buttons-section {
      display: flex;
      justify-content: flex-end;
      opacity: 0;
      transition: all .3s;
      visibility: hidden;
    }
  }

  &:nth-child(2n) {
    padding-top: 7px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, .05);
    .buttons-section {
      opacity: 1;
      visibility: visible;
    }
  }

  &__note {
    opacity: 0.7;
    color: rgba(0, 0, 0, 1);
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: 4px;
    font-weight: 400;
    padding-left: 0.5rem;
  }

  &__assigner {
    img {
      height: 25px;
      width: 25px;
      border: 1px solid rgba(237, 237, 238, 1);
      border-radius: 50%;
    }

    &__name {
      color: rgba(0, 0, 0, 1);
      font-size: 14px;
      letter-spacing: 0;
      line-height: 21px;
    }

    &__added {
      opacity: 0.6;
      color: rgba(0, 0, 0, 1);
      font-size: 12px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 18px;
    }
  }
}

.form-switch {
  width: 210px !important;
}

.video-info {
  img {
    width: 200px;
    height: 100px;
  }
  video {
    width: 200px;
    height: 100px;
  }
}

.profile-video {
  width: 100%;
  height: fit-content;
  border: 1px solid #dadada;
  border-radius: 8px;
  background-color: #fff;
  padding: 10px;
  video {
    width: 100%;
  }
}

.review-block {
  border-left: 1px solid #dee2e6;
  padding-left: 0;

  .border-bottom, .row {
    padding-left: 0.7rem;
  }
}

.video-block {
  padding-right: 2rem;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6;
}
</style>
