<template>
  <div class="modal modal-small-center box-shadow-light border-small" id="changeDocumentStatus">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header border-0">
        <h3>
          Change Status for '{{ document.name }}'
        </h3>
        <img alt="Close" class="cursor-pointer" src="@/assets/icons/icon-remove.svg" @click="$emit('close')">
      </div>
      <div class="modal-body">
        <div class="modal-body-container border-0 m-0">
          <div class="form-group full-width">
            <label for="fieldStatus">
              Select Status
            </label>
            <multiselect id="fieldStatus"
                         track-by="value" label="label"
                         v-model="status"
                         :close-on-select="true"
                         :options="statuses"
                         :searchable="true"
                         :show-labels="false"
                         :allow-empty="false"
                         class="full-width"
                         open-direction="bottom"
                         placeholder="Select Type">
            </multiselect>
          </div>
          <div class="d-flex mt-4">
            <button class="btn bg-green w-100" @click="update" :disabled='ui.saving'>
                <span v-if="ui.saving"
                      aria-hidden="true"
                      class="spinner-border spinner-border-sm text-light" role="status">
                </span>
                <span v-else class="text-light">
                 Update
                </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  name: 'ChangeDocumentStatus',
  props: {
    document: Object,
    statuses: Array
  },
  components: {Multiselect},
  data() {
    return {
      status: {},
      ui: {
        saving: false
      }
    }
  },
  methods: {
    getData() {
      this.status = {
        label: this.document.status,
        value: this.document.status.toLowerCase()
      }
    },
    update() {
      this.ui.saving = true;
      let formData = new FormData();
      formData.append('status', this.status.value)
      this.$http.post(`/api/v1/documents/update-status/${this.document.id}`, formData)
        .then((res) => {
          this.alertSuccess(res.data.message)
          this.$emit('success')
          this.$emit('close')
        })
        .catch((err) => {
          this.alertError(err.response.statusText)
        })
        .finally(() => {
          this.ui.saving = false;
        })
    }
  },
  created() {
    this.getData()
  }
}
</script>

<style lang="scss" scoped>
#userUploadDocuments {
  .modal-header {
    position: relative;
  }

  .modal-body {
    padding: 0 30px;

  }
}
</style>
