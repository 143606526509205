<template>
  <div class="modal modal-small-center box-shadow-light border-small" id="EditCardModal">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header border-0">
        <h3>
          Edit Credit Card
        </h3>
        <img src="@/assets/icons/icon-remove.svg" class="cursor-pointer" alt="Close" @click="$emit('close')">
      </div>
      <div class="modal-body">
        <div class="text-center mt-3 mb-4" v-if="ui.loading === true">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div class="modal-body-container border-0 mb-0 pb-0" v-else>
          <div class="col-12">
            <div class="form-group full-width">
              <label for="fieldNumber">
                Card Number
              </label>
              <input type="text" class="form-control" id="fieldNumber"
                     :class="{
                       'is-invalid': formData.number.trim().length != 19
                     }"
                     v-model="formData.number"
                     v-mask="'#### #### #### ####'">
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-6">
              <div class="form-group">
                <label for="fieldExpiry">
                  Expiry
                </label>
                <input type="text" class="form-control" id="fieldExpiry"
                       :class="{
                         'is-invalid': formData.expiry.trim().length != 7
                       }"
                       v-model="formData.expiry"
                       v-mask="'##/####'"
                       placeholder="MM/YYYY">
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="fieldSecurityCode">
                  Security Code
                </label>
                <input type="text" class="form-control" id="fieldSecurityCode"
                       :class="{
                          'is-invalid': formData.code.trim().length != 3
                       }"
                       v-model="formData.code"
                       v-mask="'###'">
              </div>
            </div>
          </div>
          <div class="col-12 mt-4">
            <div class="form-group full-width">
              <label for="fieldCardOwner">
                Name on the Card
              </label>
              <input type="text" class="form-control" id="fieldCardOwner"
                      :class="{
                         'is-invalid': formData.cardOwner.trim().length == 0
                      }"
                      v-model="formData.cardOwner">
            </div>
          </div>
        </div>
        <div class="d-flex mt-4 mb-3 pb-2" v-if="ui.loading === false">
          <button class="btn bg-green" @click="saveCard" :disabled="ui.saving || validate()">
            <span v-if="ui.saving === true"
                  class="spinner-border spinner-border-sm"
                  role="status" aria-hidden="true">
            </span>
            <span v-else>Save Card</span>
          </button>
          <button class="btn btn-cancel ms-3" @click="$emit('close')">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditCardModal',
  props: {
    userId: Number,
    creditCard: Object
  },
  data() {
    return {
      formData: {
        number: '',
        expiry: '',
        code: '',
        cardOwner: ''
      },
      ui: {
        loading: false,
        saving: false,
      }
    }
  },
  methods: {
    validate() {
      if (this.formData.number.trim().length !== 19 ||
          this.formData.expiry.trim().length !== 7 ||
          this.formData.code.trim().length === 0 ||
          this.formData.cardOwner.trim() === ''
      ) {
        return true
      }
      return false
    },
    getData() {
      this.ui.loading = true;
      this.formData = this.creditCard
      setTimeout(() => {
        this.ui.loading = false;
      }, 500)
    },
    saveCard() {
      this.ui.saving = true;

      let formData = new FormData()
      formData.append('number', this.formData.number)
      formData.append('expiry', this.formData.expiry)
      formData.append('code', this.formData.code)
      formData.append('cardOwner', this.formData.cardOwner)

      this.$http.post(`/api/v1/staff/update-card/${this.userId}`, formData)
        .then(() => {
          this.alertSuccess('Credit Card updated')
          this.$emit('success')
          this.$emit('close')
        })
        .catch((err) => {
          this.alertError(err.response.statusText)
        })
        .finally(() => {
          this.ui.saving = false;
        })
    }
  },
  created() {
    this.getData();
  }
}
</script>

<style lang="scss" scoped>
#EditCardModal {
  .modal-header {
    position: relative;
  }

  .modal-body {
    padding: 0 30px;

    .card-info {
      &-title {
        opacity: 0.46;
        color: #000000;
        font-size: 11px;
        letter-spacing: 0;
        line-height: 12px;
      }
    }
  }
}
</style>
